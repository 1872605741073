import React, {useContext,useEffect,useState} from 'react';
import axios from 'axios';
import Context from '../../user_details';
import {Collapse} from 'react-bootstrap';

const AssignQuestionsModal = ({sectionValues, selectedSection, setSelectionQuestions, selectedQuestions, currentComplexity, setSectionValues, questionsLoader, isexamPatternSelected, availableQuestions,questionsAvailabilityPerPick, showAssignModal, setShowAssignModal}) => {
    
    const [complexityLabels,setComplexityLabel] = useState({1:"easy", 2: "medium", 3: 'hard'}); 
    const [comprehensionTogglers, setComprehensiveExpandTogglers] = useState({});
    console.log("assignquestions model here==<",currentComplexity, questionsAvailabilityPerPick);
    const SaveChanges = () => {
        let questionType = sectionValues[selectedSection - 1].question_types[0].question_type;
        let temporary = sectionValues;
        if (questionType === 1 || questionType === 2 ) {
          temporary[selectedSection - 1].assignedQuestions[0][questionType][currentComplexity].question_ids = selectedQuestions;
        }
        else {
          temporary[selectedSection - 1].assignedQuestions[0][questionType].question_ids = selectedQuestions;
        }
        setSectionValues([...temporary]);
        setShowAssignModal(false);
    }

    const checkQuestionIsChecked = (questionId) => {
        let questionType = sectionValues[selectedSection - 1]?.question_types[0]?.question_type;
        let assignedQuestions = sectionValues[selectedSection - 1]?.assignedQuestions[0];
        console.log(`${questionId} these are assigned questions with complexity ${currentComplexity} and type ${questionType}`, assignedQuestions, questionType, );
        if (questionType == 1 || questionType == 2 || questionType == 3) {
          const isChecked =  assignedQuestions[questionType][currentComplexity]?.question_ids.indexOf(questionId) > -1;
          console.log("these are assigned questions returning", isChecked, questionId)
          return isChecked;
        }
        else {
          return assignedQuestions[questionType]?.question_ids?.indexOf(questionId) > -1;
        }
    };

    const changeAssignedQuestions = (event) => {
        let questionType = sectionValues[selectedSection - 1]?.question_types[0]?.question_type;
        let assignedQuestions = sectionValues[selectedSection - 1]?.assignedQuestions[0];
        if (questionType == 1 || questionType === 2 || questionType == 3) {
          assignedQuestions = assignedQuestions[questionType][currentComplexity]?.question_ids;
        }
        else {
          assignedQuestions = assignedQuestions[questionType].question_ids;
        }
        if (event.target.checked) {
          assignedQuestions.push(event.target.value);
        }
        else {
          assignedQuestions.splice(assignedQuestions.indexOf(event.target.value), 1);
        }
        setSelectionQuestions(assignedQuestions);
      }

    
      const generateQuestionsArray = () => {
        const qType = Number(sectionValues[selectedSection - 1].question_types[0].question_type);
        switch(qType) {
          case 1:
          case 2:
          case 3: {
            return questionsAvailabilityPerPick[complexityLabels[currentComplexity]];
          };
          case 4: 
          default: {
            return questionsAvailabilityPerPick.questions;
          } 
        }
       
      }

    return (
        <div className="modal fade assign-modal assign-questions-container" data-bs-backdrop="static" data-bs-keyboard="false" id="assignmodel" tabIndex="-1" role="dialog" aria-labelledby="assignmodelTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered assign-modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header justify-content-between">
                    <h5 className="modal-title " id="exampleModalLongTitle">Assign Questions</h5>
                  </div>
                  <div className="modal-body assign-modal-body">
                    {showAssignModal && 
                     !questionsLoader && isexamPatternSelected ?
                      generateQuestionsArray()?.length > 0 ?
                        <div className='h-100 w-100 d-flex flex-column align-items-stretch custom-scrollbar-styles' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                          <div className='row'>
                            { generateQuestionsArray().map(question => {
                              return (
                                (() => {
                                  const qType = Number(sectionValues[selectedSection - 1].question_types[0].question_type);
                                  switch(qType) {
                                    case 1:
                                    case 2:
                                    case 3: {
                                      return (
                                        <div className='col-md-12' style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                                          <div className='assign_questions-item border p-3'>
                                            <label className='select-question-item'>
                                              <input type="checkbox" className='questions-checkbox' value={question._id} defaultChecked={checkQuestionIsChecked(question._id)} onChange={(e) => { changeAssignedQuestions(e) }} />
                                              <div className='question-content' dangerouslySetInnerHTML={{ __html: question.question }}></div>
                                            </label>
                                            <div className='answers-option-container'>
                                              {
                                                question.answers.map((answer, index) => {
                                                  return <p key={index} className={answer.is_correct ? "answers-option text-success" : "answers-option text-danger"} >{index + 1}. &nbsp;{answer.answer} </p>
                                                })
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    };
                                    case 4: 
                                    default: {
                                      return (
                                        <div className='col-md-12' style={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                                        <div className='assign_questions-item border p-3'>
                                          <button className='nexus-button-primary float-end mb-2 d-flex aign-items-center gap-1'
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setComprehensiveExpandTogglers(prev => ({...prev, [question._id]: !comprehensionTogglers[question._id]}))
                                             }}
                                          >
                                            <i class="bi bi-eye"></i> <i className='fw-medium'>Passage</i>
                                          </button>
                                          <label className='select-question-item'>
                                            <input type="checkbox" className='questions-checkbox' value={question._id} defaultChecked={checkQuestionIsChecked(question._id)} onChange={(e) => { changeAssignedQuestions(e) }} />
                                            <div className='w-100 d-flex flex-column ps-3'>
                                              <Collapse in={comprehensionTogglers[question._id]} 
                                                aria-controls={`cQ-table-${question._id}`}
                                                aria-expanded={comprehensionTogglers[question._id]} 
                                              > 
                                                <div className='question-content overflow-y-auto pe-3' id={`cQ-table-${question._id}`} style={{maxHeight: "6rem"}}  dangerouslySetInnerHTML={{ __html: question.comprehensive_id.content }}></div>
                                              </Collapse>
                                              <div className='d-flex flex-column w-100'>
                                              <p className='ps-1 fw-medium nexus-color-primary-note'>Alloted Questions </p>
                                              <div className='px-1'>
                                                <table className='nexus-table-primary w-100'>
                                                  <thead>
                                                    <td className='w-75'>Question</td>
                                                    <td>Answers</td>
                                                  </thead>
                                                </table>
                                              </div>
                                              <div className='w-100 px-1 overflow-y-auto' style={{maxHeight: '25rem'}}>
                                                  <table className='nexus-table-primary w-100'>
                                                    <tbody>
                                                      {question.comprehensive_questions.map(cQ => (
                                                        <tr>
                                                          <td className='w-75'>
                                                            <div dangerouslySetInnerHTML={{__html: cQ.question}}></div>
                                                          </td>
                                                          <td>
                                                            <ol className='ms-5'>
                                                              {cQ.answers.map(answer => (
                                                                <li className={`text-start  ${answer.is_correct ? 'nexus-color-primary-info fw-medium': 'fw-normal text-muted'}`} style={{wordBreak: 'break-word'}}>{answer.answer}</li>
                                                              ))}
                                                            </ol>
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                              </div>
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      )
                                    } 
                                  }

                                })()
                              )
                            })}
                          </div>
                        </div>
                        : "Questions not available" : <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                      </div>
                    }
                  </div>
                  <div className="modal-footer align-items-center justify-content-center">
                    <button type="button" className="btn app-main-form-btn-only-brdr" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn app-main-form-btn" data-bs-dismiss="modal" onClick={SaveChanges}>Save changes</button>
                  </div>
                </div>
              </div>
            </div>
    );
}

export default AssignQuestionsModal;