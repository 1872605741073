import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './topics.css'; // Import CSS file
import AddTopicForm from '../AddTopicForm/addTopicForm'; // Import the AddTopicForm component
import Context from "../../user_details";
import SoundButton from '../../helpers/soundButton';
import SpinnerLoader from '../../helpers/spinner-loader';
import NoData from '../../assets/no-data.jpg';


export default function Topics() {
  const context = useContext(Context);
  const user = context.user;
  const [topics, setTopics] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(); // State variable to manage form visibility
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchTopics();
  }, [page]); // Fetch topics whenever page changes

  const fetchTopics = async () => {
    try {
      setLoading(true);
      // const response = await axios.get(`${process.env.REACT_APP_API_URL}/topics?page=${page}`);
      console.log(user);
      let url = `${process.env.REACT_APP_API_URL}/qb/topics?page=${page}&client_id=${user.firm_id}`;
      if(user.user_type === "superAdmin") {
        url = `${process.env.REACT_APP_API_URL}/qb/topics?page=${page}`;
      }
      const response = await axios.get(url);
      const { topics, totalPages } = response.data;
      setTopics(topics);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
    finally{
      setLoading(false);
    }
  };

  const handleTopicAdded = () => {
    // Close the form and refresh the topic list
    //setShowForm(false);
    setTimeout(function(){setShowForm(false);}, 2000)
    fetchTopics();
  };

  const addBtnHandler = async() => {
    const hasPermission = await user.permissionTypes.includes("createTopics");
    if ( hasPermission)
    {
    setShowForm(true);
    setSelectedTopic();
    }
 };

  const editBtnHandler = async(topic) => {
    const hasPermission = await user.permissionTypes.includes("editTopics");
    if(hasPermission) {
    setShowForm(true);
    setSelectedTopic(topic);
    }
};

const cancelBtnHandler = () => {
  setShowForm(false);
};

const deleteHandler = async (topic_id) => {
  try {
    const hasPermission = await user.permissionTypes.includes("deleteTopics");
    if(hasPermission) {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/qb/topics/${topic_id}`
    ).then(d => {
        fetchTopics();
    });
  }
  } catch (error) {
    console.error("Error Update Deleted:", error);
  }
};


  return (
    <div className="topics-container h-100">
      {/* <h2>Topics</h2>
      <button onClick={() => setShowForm(!showForm)}>Add Topic</button>
      {showForm && <AddTopicForm onAddTopic={handleTopicAdded} />} */}

      {/* =============================================== */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8">
            <div class="page-header-container">
              <h2 class="page-main-header">Topics</h2>
              {user.permissionTypes.includes("createTopics") && 
              <button onClick={addBtnHandler} class="add-form-btn">
                <span class="icon-placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                    <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
                  </svg>
                </span>
                Add Topic
              </button>
      }
      
            </div>
          </div>
          <div class="col-sm-4"> &nbsp; </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">&nbsp;</p>
            {showForm && <AddTopicForm onAddTopic={handleTopicAdded} cancelBtnHandler={cancelBtnHandler} selectedTopics={selectedTopic} />}
          </div>
        </div>
      </div>
      {/* ================================================ */}

      {/* <ul className="topic-list">
        {topics.map((topic, index) => (
          <li key={index} className="topic-item">
            <span className="topic-name">{topic.topic_name}</span>
            <span className="assigned-subject">Assigned Subject: {topic.subject_id ? topic.subject_id.subject_name : 'N/A'}</span>
            <span className="created-date">Created: {new Date(topic.created.date).toLocaleDateString()}</span>
          </li>
        ))}
      </ul> */}

      {/* ================================================ */}
      {loading ? (
             <div className="d-flex align-items-center justify-content-center h-100">
             <SpinnerLoader />
             </div>
      ): topics.length === 0 ? (
        <div className="no-data-container text-center">
          <img src={NoData} alt="No Data Available" className="no-data-image" />
          <p>No Topics available.</p>
        </div>
      ) : !showForm && (
          <>
      <ul className="topic-list row row-cols-1 row-cols-md-4 g-4">
        {topics.map((topic, index) => (
          <li key={index} className="topic-item page-added-item col">
            <div class="added-items-list card h-100">
              <div class="card-body">
                <h5 class="card-title min-ht-25">{topic.topic_name}</h5>
                <p class="card-text">
                  <strong>Assigned Subject:</strong>
                  {topic.subject_id ? topic.subject_id.subject_name : 'N/A'}
                </p>
                {context.user.user_type == "superAdmin" && (
                <p class="card-text mb-1">
                  <strong>Client Name:</strong>
                  {topic.clientDetails?.firm_name ? topic.clientDetails.firm_name : 'N/A'}
                </p>
                 )}
              </div>
          
              <div class="card-footer">
              {user.permissionTypes.includes("editTopics") && 
              <button className="delete_item" id="editQuestion" onClick={() => editBtnHandler(topic)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg> &nbsp; Edit  
                </button>
              }
              {user.permissionTypes.includes("deleteTopics") &&
                <button className="delete_item" id="editQuestion" onClick={() => { if(window.confirm('Are you sure to delete this Topic?')) deleteHandler(topic._id)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                </svg> Delete </button>
              }
                <p class="text-body-secondary mb-0">
                  <strong>Created:</strong> {new Date(topic.created.date).toLocaleDateString()}
                </p>
                </div>
            </div>
          </li>
        ))}
      </ul>
      </>
      )}
      {/* ================================================ */}

      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}>
              <SoundButton className="page-link" onClick={() => setPage(pageNumber + 1)}>
                {pageNumber + 1}
              </SoundButton>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
