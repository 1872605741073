import {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import SpinnerLoader from '../../helpers/spinner-loader';
import axios from 'axios';
import './comprehensiveQform.scss';
import InjectCKEditor from './ckEditor/ckEditor';

const AnswerOptions = ({questionForm, setQuestionForm}) => {
    const questionType = Number(questionForm.questionType);

    return (
        <div className='h-100 w-100 overflow-y-auto py-3'>
           {questionForm.answers.filter(answer => (answer.status == 1)).length?
            <table className='w-100 border-0 answer-table' style={{borderSpacing: "0 10px"}}>
                <tbody >
                    {questionForm.answers.filter(answer => (answer.status == 1)).map((answerOption, index) => (
                            <tr key={answerOption.key} className='w-100 my-1'>
                                <td >
                                <div className='d-flex w-100 h-100 align-items-stretch justify-content-center p-2' >
                                        <input type="text" className='answer-option h-100 p-2  flex-grow-1'
                                            onChange={(e) => {
                                                let {answers} = questionForm;
                                                answers = answers.map(ans => {
                                                    if(ans.key == answerOption.key) {
                                                        ans.answer = e.target.value;
                                                    }
                                                    return ans;
                                                })
                                                setQuestionForm(prev => ({...prev, answers}));
                                            }} 
                                            defaultValue={answerOption.answer} 
                                            style={{fontSize: "13px", borderRadius: "6px 0px 0px 6px"}} 
                                            disabled={questionType == 2}/>
                                        <div className='d-flex align-items-center justify-content-start gap-1 bg-light px-2 py-auto'>
                                            <input  className='' {
                                                        ...(() => {
                                                            switch (questionType) {
                                                                case 1:
                                                                case 2: {
                                                                    return { name: "option", type: "radio" }
                                                                };
                                                                case 3: return { type: "checkbox" };
                                                            }
                                                        })()
                                                    }
                                                defaultChecked={answerOption.is_correct}
                                                style={{transform: 'scale(1.25)'}}
                                                onChange={(e) => {
                                                    let {answers} = questionForm;
                                                    const {checked: checkedValue} = e.target;
                                                    switch(questionType) {
                                                        case 1:
                                                        case 2: {
                                                            answers = answers.map(ans => {
                                                                if(ans.key == answerOption.key) {
                                                                    ans.is_correct = Boolean(checkedValue);
                                                                } else {
                                                                    ans.is_correct = false;
                                                                }
                                                                return ans;
                                                            });
                                                            break;
                                                        };
                                                        case 3: {
                                                            answers = answers.map(ans => {
                                                                if(ans.key == answerOption.key) {
                                                                    ans.is_correct = Boolean(checkedValue);
                                                                } 
                                                                return ans;
                                                            });
                                                            break;
                                                        }
                                                    }
                                                    setQuestionForm(prev => ({...prev, answers}));
                                                }}
                                            />
                                            <p className='m-0 fw-medium' style={{fontSize: "0.75rem"}}> Is Correct Answer</p>
                                        </div>
                                    {questionType != 2 && <div className='d-flex align-items-center justify-content-center ms-2'>
                                            <button className='bg-danger text-white rounded border-none px-2 py-1' onClick={() => {
                                                let answers = questionForm.answers;
                                                answers = answers.map(ans => {
                                                    if(ans.key == answerOption.key) {
                                                        return {...ans, status: 2};
                                                    } else {
                                                        return ans;
                                                    }
                                                });
                                                setQuestionForm(prev => ({...prev, answers}));
                                            }}><i class="bi bi-trash-fill"></i></button>
                                        </div>}
                                </div>
                                </td>
                            
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            :
            <div className='h-100 w-100'>
                <div className=' fw-medium bg-light nexus-color-primary-note h-100 w-100 d-flex align-items-center text-center justify-content-center'>Please add atleast one option </div>
            </div>
            }
        </div>
    )
    
}

export default function ComprehensiveQForm({formType}) {
    const params = useParams();
    const navigate = useNavigate();
    const [questionForm, setQuestionForm] = useState({});
    const [ loader, setLoader] = useState(true);
    const [ answerOptionsLoader, setOptionsLoader] = useState(false);
    const {questionId, comprehensiveQid} = params;
    const [showCanvas, setShowCanvas] = useState(false);
    const [canvasBody, setCanvasBody] = useState('');
    const [questionTypes, setQuestionTypes] = useState([
        {label: "Multiple Choice", value: '1'},
        {label: "True / False", value: '2'},
        {label: "Checkboxes", value: '3'}
    ])

    useEffect(() => {
        switch(formType) {
            case 'Create': {
                if(!questionId) {
                    setCanvasBody(
                        <div className='border border-2 border-danger p-4 fw-medium text-danger bg-white d-flex align-items-center'>
                                <i class="bi bi-exclamation-triangle-fill fs-5"></i>&nbsp;
                                Something went wrong
                        </div>
                    )
                    setShowCanvas(true);
                    setTimeout(() => {
                        setShowCanvas(false)
                    }, 2000);
                } else {
                    getQuestionDetails(questionId);
                }
                break;
            };
            default: {
                if (!questionId || !comprehensiveQid) {
                    setCanvasBody(
                        <div className='border border-2 border-danger p-4 fw-medium text-danger bg-white d-flex align-items-center'>
                            <i class="bi bi-exclamation-triangle-fill fs-5"></i>&nbsp;
                            Something went wrong
                        </div>
                    )
                    setShowCanvas(true);
                    setTimeout(() => {
                        setShowCanvas(false)
                    }, 2000);
                } else {
                    getQuestionDetails(questionId);
                }
                break;
            }
        }
    },[]);

    useEffect(() => {
        console.log(questionForm, " this is question form")
    }, [questionForm]);

    const noticifyErrorMessage = (msg) => {
        setCanvasBody(
            <div className='border border-2 border-danger p-4 py-2 text-danger fw-medium d-flex align-items-center'>
                <i class="bi bi-exclamation-diamond-fill fs-4"></i>&nbsp;&nbsp;{msg || 'Something Went Wrong'}
            </div>
        )
        setShowCanvas(true);
        setTimeout(() => {
            setShowCanvas(false);
        }, 2000);
    }

    const noticifySuccessMessage = (msg) => {
        setCanvasBody(
            <div className='border border-2 border-success p-4 py-2 text-success fw-medium d-flex align-items-center'>
               <i class="bi bi-check fs-5"></i>&nbsp;&nbsp;{msg || 'Successfully Completed!'}
            </div>
        )
        setShowCanvas(true);
        setTimeout(() => {
            setShowCanvas(false);
        }, 2000);
    }

    const formValidator = async() => {
        try {
            const questionValidation = async() => {
                const validation = Boolean(window.editor.getData().length)
                if(validation) {
                    return true;
                } else {
                    throw new Error('Question Cannot Be Empty');
                }
            };

            const answersValidation = async() => {
                let {answers} = questionForm;
                answers = await answers.filter(ans => (ans.status == 1));
                let atleastOneCorrect = false;
                for(let ans of answers) {
                    if(!ans.answer.length) {
                        throw new Error("Options Cannot Be Empty!")
                    }
                    if(ans.is_correct) {
                        atleastOneCorrect = true;
                        break;
                    }
                }
                if (atleastOneCorrect) { 
                    return true;
                } else {
                    throw new Error('Please provide options with atleast 1 correct answer');
                }
            };

            return await Promise.all([
                questionValidation(),
                answersValidation()
            ])
            .then(() => {
                return true;
            })
            .catch(e => {
                noticifyErrorMessage(e.message);
                return false;
            }) 

        }
        catch(e) {
            console.error(e);
            noticifyErrorMessage(' Something went wrong. Please Check the Form');
        }
    }

    const handleSubmit = async() => {
        try {
            const validation = await formValidator();
            if(!validation) return;
            switch(formType) {
                case 'Create': {
                    try {
                        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/qb/comprehensive/add-question/${questionId}`, {...questionForm, question: window.editor.getData()});
                        if (data.success) {
                            noticifySuccessMessage('Question Added Successfully');                            
                        } else {
                            noticifyErrorMessage('Something Went Wrong');
                            // navigate('/questions')
                        }
                    } catch(e) {
                        console.error(e);
                        noticifyErrorMessage("Something Went Wrong !!!");
                        // navigate('/questions')
                    }
                    break;
                };
                case 'Edit': {
                    try {
                        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/qb/comprehensive/edit-question/${questionId}/${comprehensiveQid}`, {...questionForm, question: window.editor.getData()});
                        if (data.success) {
                            noticifySuccessMessage('Question Edited Successfully');
                        } else {
                            noticifyErrorMessage('Something Went Wrong');
                            // navigate('/questions')
                        }
                    } catch (e) {
                        console.error(e);
                        noticifyErrorMessage("Something Went Wrong !!!");
                        // navigate('/questions')
                    }
                    break;
                    
                }
                default: {
                    noticifyErrorMessage("Something Went Wrong !!!");
                    window.href.location = "/";
                }
            }
            // throw new Error();
        } catch(e) {
            console.error(e);
            setCanvasBody(
                <div className='border border-2 border-danger p-4 text-danger fw-medium d-flex align-items-center'>
                    <i class="bi bi-exclamation-diamond-fill fs-4"></i>&nbsp;&nbsp;Something went wrong. Please Try Again
                </div>
            )
            setShowCanvas(true);
            setTimeout(() => {
                setShowCanvas(false);
            }, 2000);
        }
    }

    const getQuestionDetails = async(questionId) => {
        try {
            setLoader(true);
            const {data: questionDetails} = await axios.get(`${process.env.REACT_APP_API_URL}/qb/question/comprehensive/read/${questionId}/${comprehensiveQid? comprehensiveQid: ""}`);
            setQuestionForm({
                comprehensive_id: questionDetails.comprehensive_id._id,
                content: questionDetails.comprehensive_id.content,
                questionType: questionDetails.comprehensive_questions? questionDetails.comprehensive_questions.question_type: "",
                question: questionDetails.comprehensive_questions? questionDetails.comprehensive_questions.question: "",
                answers: questionDetails.comprehensive_questions? (() => {
                    return questionDetails.comprehensive_questions.answers.map((ans,key) => ({...ans,key}) )
                })(): []
            });
            setLoader(false);
            
        } catch(e) {
            console.error(e);
            setCanvasBody(
                <div className='border border-2 border-danger p-4 fw-medium text-danger bg-white d-flex align-items-center'>
                        <i class="bi bi-exclamation-triangle-fill fs-5"></i>&nbsp;
                        Something went wrong
                </div>
            )
            setShowCanvas(true);
            setTimeout(() => {
                setShowCanvas(false)
            }, 2000);
            
        } finally {
            
        }
    }

    return (
        <div className='h-100 w-100'>
            <Offcanvas show={showCanvas} placement='end' style={{'height': 'fit-content','width': 'fit-content'}}>
                <Offcanvas.Body className='p-0'>
                    {canvasBody}
                </Offcanvas.Body>
            </Offcanvas>
            {loader? 
                <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                    <SpinnerLoader />
                </div>
                :
                <div className='w-100 nexus-form-secondary h-100 C-QForm'>
                    <div className='nexus-form-secondary-title'>{formType} Compehensive Question Form</div>
                    <div className='d-flex gap-2 justify-content-center pt-3 h-90'>
                        <div className='w-50 h-100 d-flex flex-column align-items-stretch'>
                                <p className='nexus-label-500'>Comprehensive</p>
                                <div className='p-3 h-100 overflow-y-auto'>{questionForm.content}</div>
                        </div>
                        <div className='w-50 h-100 px-1 py-4 '>
                            <div id="cke5-mathtype-demo" className='h-50 '>
                                <InjectCKEditor selectedQuestion={questionForm.question}/>
                            </div>
                            <div className='answers-container d-flex flex-column align-items-stretch h-50 gap-2'>
                                <div className='d-flex justify-content-between flex-wrap'>
                                    <div className='nexus-label-500'>Answers</div>
                                    <div className='d-flex gap-2'>
                                        <div className='d-inline-flex gap-2'>
                                            <p className='nexus-label-500'>Question Type:</p>
                                            <select className='nexus-dropdown'
                                                onChange={(e) => {
                                                    setOptionsLoader(true);
                                                    const type = Number(e.target.value);
                                                    switch(type) {
                                                        case 2: {
                                                            setQuestionForm((prev) => ({ 
                                                                ...prev, 
                                                                questionType: e.target.value,
                                                                answers: [
                                                                    {answer: "True", status: 1, key: 0, is_correct: false},
                                                                    {answer: "False", status: 1, key: 1, is_correct: false}
                                                                ]
                                                            }));
                                                            break;
                                                        }
                                                        default: {
                                                            setQuestionForm((prev) => ({ 
                                                                ...prev, 
                                                                questionType: e.target.value,
                                                                answers: []
                                                            }));
                                                            break;
                                                        }
                                                    }
                                                    setTimeout(() => {
                                                        setOptionsLoader(false);
                                                    }, 1);
                                                }}
                                                value={questionForm.questionType}
                                            >
                                                <option value='' disabled>--Select a type---</option>
                                                {questionTypes.map(questionType => (<option value={questionType.value}>{questionType.label}</option>))}
                                            </select>
                                        </div>
                                        <button className='btn ms-auto d-flex gap-2 align-items-center bg-success text-white add-answer-button rounded'
                                            onClick={() => {
                                                setQuestionForm(prev => ({...prev, answers: [...prev.answers, {answer: "", status: 1, is_correct: false, key: prev.answers.length}]}))
                                            }}
                                            disabled={(() => {
                                                const type = Number(questionForm.questionType);
                                                switch(type) {
                                                    case 2: return true;
                                                    default: return false;
                                                }
                                            })()}
                                        >
                                            <i class="bi bi-plus-square-fill"></i>
                                            Add Answer
                                        </button>
                                        
                                    </div>
                                </div>
                               
                                <div className='options-container h-100'>
                                    {questionForm.questionType ?
                                        <div className='h-100 w-100 overflow-y-auto py-3'>
                                            {answerOptionsLoader ? 
                                                <div className='h-100 w-100 d-flex align-items-center justy-content-center'>
                                                    <SpinnerLoader/>
                                                </div>
                                                :
                                                <AnswerOptions questionForm={questionForm} setQuestionForm={setQuestionForm}/>
                                            }
                                        </div>
                                        :
                                        <div className='h-100 w-100 bg-white d-flex align-items-center justify-content-center '>
                                            <p className='m-0 fw-medium nexus-color-primary-note'>Please Select a Question Type</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-3 justify-content-center py-4'>
                        <button className='nexus-button-danger'>Cancel</button>
                        <button className='nexus-button-primary' onClick={handleSubmit}>{formType}</button>
                    </div>
                </div>
            }
           
        </div>
    )
}