// soundHelper.js
import successSound from '../assets/sounds/success.mp3';
import errorSound from '../assets/sounds/error.mp3';

export const playSound = (soundType) => {

    let sound;
    switch(soundType) {
        case 'ERROR': {
            sound = errorSound;
            break;
        }
        case 'NOTICE': {
            sound = errorSound;
            break;
        }
        case 'WARNING': {
            sound = errorSound;
            break;
        }
        default: {
            sound = successSound;
            break;
        }
    }

    const audio = new Audio(sound);
    audio.play();
};
