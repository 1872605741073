import React, { useState, useEffect, useContext } from 'react';
import './AddExamForm.css';
import './AddExamForm.scss'
import axios from 'axios';
import Context from '../../user_details';
import { useNavigate } from 'react-router-dom';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import sampleUploadEmailsFormat from '../../assets/sampleEmailsUploadFormat.xlsx';
import { assignedQuestionsSchema, fetchAvailableQuestions, processAssignedQuestions, processAssignedQuestionsPick, emailsFileUploadHelper } from './questionHelpers';
import QuestionComplexityTable from './questionComplexTable';
import AssignQuestionsModal from './assignQuestionsModel';
import { handleSubmitHelper } from './handleSubmit';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SpinnerLoader from '../../helpers/spinner-loader';
import TimeZoneDropdown from './TimezoneDropDown';
import { playSound } from '../../helpers/otherSounds';

const getDefaultDateTime = (date) => {
  if (!date) {
    return '';
  } else {
    const timeZone = 'Asia/Kolkata';
    const utcDate = new Date(date);
    const zonedDate = toZonedTime(utcDate, timeZone);
    const formattedDateTime = formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd\'T\'HH:mm');
    return date;
  }
}

export default function CreateExamForm(props) {
  let overlayPanelRef = React.createRef();
  const questionTypesToName = { 1: 'Multiple Choice', 2: 'True/False', 3: "CheckBoxes", 4: 'Comprehensive', 5: 'File Upload', 6: 'Programatical' };
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const [examName, setExamName] = useState('');
  const [selectedExamPattern, setSelectedExamPattern] = useState({});
  const [selectedUserGroup, setSelectedUserGroup] = useState({});
  const [sections, setSections] = useState([]);
  const [examPatterns, setExamPatterns] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [topics, setTopics] = useState([]);
  const [examFormLoader, setExamFormLoader] = useState(false);
  const [batchLoader, setbatchLoader] = useState(false);
  const [courseLoader, setcourseLoader] = useState(false);
  const [isexamPatternSelected, setIsExamPatternSelected] = useState(false);
  const [isSubjectSelected, setIsSubjectSelected] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [Batch, setBatch] = useState([]);
  const [selectedSection, setSelectedSection] = useState(1);
  const [sectionValues, setSectionValues] = useState([]);
  const [numberOfSections, setNumberOfSections] = useState(0);
  const [sectionValueLoader, setSectionValueLoader] = useState(false);
  const [selectedCourse, setselectedCourse] = useState({});
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [questionsLoader, setQuestionsLoader] = useState(false);
  const [currentComplexity, setCurrentComplexity] = useState(0);
  const [selectedQuestions, setSelectionQuestions] = useState([]);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [questionPickLoader, setQuestionPickLoader] = useState(false);
  const [topicLoader, setTopicLoader] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [negativeMarks, setNegativeMarks] = useState(Object.keys(props).length && Object.keys(props?.selectedExam).includes("negative_marks") ? props?.selectedExam.negative_marks.marks : 0);
  const [questionsPerNegative, setQuestionsPerNegative] = useState(Object.keys(props).length && Object.keys(props?.selectedExam).includes("negative_marks") ? props?.selectedExam.negative_marks.no_of_questions : 0);
  const [passMarks, setPassMarks] = useState(Object.keys(props).length && Object.keys(props?.selectedExam).includes("pass_marks") ? props?.selectedExam.pass_marks : 0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [actionLoaderMessage, setActionLoaderMessage] = useState("");
  const [examStartDate, setExamStartDate] = useState(getDefaultDateTime(""));
  const [selectedExamTimeZone, setselectedExamTimeZone] = useState(Object.keys(props).length && Object.keys(props.selectedExam).includes("timeZoneOffset") ? props.selectedExam.timeZoneOffset.zone : 'Asia/Kolkata');
  const [examUtcOffset, setExamUtcOffset] = useState(Object.keys(props).length && Object.keys(props.selectedExam).includes("timeZoneOffset") ? props.selectedExam.timeZoneOffset.offset : '+5:30');
  const [examEndDate, setExamEndDate] = useState(getDefaultDateTime(""));
  const [questionsAvailabilityPerPick, setQuestionsAvailabilityPerPick] = useState(null);
  const [complexityValuePerQuestionType, setComplexityValuePerQuestionType] = useState({ easy: 1, medium: 2, hard: 3 });
  const [selectedFirm, setSelectedFirm] = useState(Object.keys(props).length > 0 ? props.selectedExam.client_id : user.user_type == 'superAdmin' ? '' : user.firm_id)
  const [isFirmsLoaded, setFirmsListLoaded] = useState(Object.keys(props).length > 0 ? true : !(user.user_type == 'superAdmin'));
  const [firmsList, setFirmsList] = useState([]);
  const [noTopicMessage, setNoTopicMessage] = useState('');
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showValidationCanvas, setShowValidationCanvas] = useState(false);
  const [validationCanvasMessage, setValidationCanvasMessage] = useState(null);

  useEffect(() => {
    const getFirmsList = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
        setFirmsList(data.results);
        setFirmsListLoaded(true);
      }
      catch (e) {
        console.error(e);
      }

    }
    if (!isFirmsLoaded) {
      getFirmsList();
    }
  }, []);

  useEffect(() => {
    const reBaseFetchedDetails = async() => {
      await fetchExamPatterns();
      await fetchCourse();
      await fetchBatch();
      await fetchSubjects();
      await fetchTopics();
    }
    if(user.user_type == 'superAdmin' && !Object.keys(props).length && selectedFirm ) {
      reBaseFetchedDetails();
    }
  }, [selectedFirm])

  useEffect(() => {
    if (fileData.length > 0) {
      let emails = fileData.join(',');
      document.getElementById('examineeEmails').value = emails;
    }
  }, [fileData]);

  // useEffect(() => {
  //   console.log([examStartDate, examEndDate])
  // }, [examStartDate, examEndDate])

  // useEffect(() => {
  //   console.log([selectedExamTimeZone, examUtcOffset], "called add exam form,")
  // }, [selectedExamTimeZone, examUtcOffset])

  useEffect(() => {
    if (actionLoader) {
      if (Object.keys(props).length > 0) {
        setActionLoaderMessage("Updating Exam... Please Wait");
      } else {
        setActionLoaderMessage("Creating Exam.... Please Wait..");
      }
    } else {
      setActionLoaderMessage("");
    }
  }, [actionLoader])

  // Fetch exam patterns
  useEffect(() => {
    // Fetch exam patterns from API and set options
    // Example: fetchExamPatterns().then((patterns) => setExamPatterns(patterns));
    if (Object.keys(props).length > 0) {
      setUpdateLoader(true);
      setExamStartDate(getDefaultDateTime(props.selectedExam.startDate));
      setExamEndDate(getDefaultDateTime(props.selectedExam.endDate));
    }
    fetchExamPatterns();
    fetchCourse();
  }, []);

  useEffect(() => {
    if (isexamPatternSelected) {
      if (updateLoader) {
        setUpdateLoader(false);
      }
    } else {
      setQuestionsAvailabilityPerPick(null);
    }
  }, [isexamPatternSelected])

  useEffect(() => {
    // console.log(examStartDate, "changed", examEndDate, new Date(examStartDate) > new Date(examEndDate))
    if (new Date(examStartDate) > new Date(examEndDate)) {
      setExamEndDate(getDefaultDateTime(""));
    }
  }, [examStartDate])

  useEffect(() => {
    // console.log("updadte loader", updateLoader)
    if (Object.keys(props).includes("selectedExam") && updateLoader) {
      if (props.selectedExam?.selectedExamPattern?.login_required) {
        setSelectedUserGroup(props.selectedExam.user_group);
      }
      setExamName(props.selectedExam.examName);
      setSelectedExamPattern(props.selectedExam.selectedExamPattern);
      setselectedCourse(props.selectedExam.selectedCourse);
      if (!props.selectedExam.selectedExamPattern.single_subject_exam) {
        setSelectedSubject(props.selectedExam.sections[selectedSection - 1].selectedSubject);
      }
      else setSelectedSubject(props.selectedExam.selectedSubject);
      setNumberOfSections(props.selectedExam.sections.length);
    }
  }, [updateLoader]);

  useEffect(() => {
    if (topics.length > 0) {
      setTopicLoader(false);
      if(!updateLoader && props.selectedExam) calculateQuestionsPickAvailability();
    }
  }, [topics]);

  useEffect(() => {
  }, [availableQuestions]);

  useEffect(() => {
    if (!updateLoader) {
      if (currentComplexity === 1 || currentComplexity === 2) {
        setSelectionQuestions(sectionValues[selectedSection - 1].assignedQuestions[0][currentComplexity].question_ids)
      }
    }
  }, [currentComplexity]);


  useEffect(() => {
    console.log("course is ", selectedCourse);
    if (Object.keys(selectedCourse).length > 0 && !updateLoader) {
      let temporary = sectionValues;
      temporary = temporary.map(element => {
        let assignedQuestions = processAssignedQuestionsPick(element, assignedQuestionsSchema);
        return { 
          ...element, 
          selectedSubject: '', 
          selectedTopic: '', 
          assignedQuestions, 
          startDate: '', 
          endDate: '', 
          availableTopics: [], 
          autoAssign: false, 
          easyAssigned: [], 
          mediumAssigned: [], 
          hardAssigned: [], 
          fileQuestionsAssigned: [],
          marks_allotment: {
            1: {
              easy: {marks: 1, negative_marks: 0},
              medium: {marks: 2, negative_marks: 0},
              hard: {marks: 4, negative_marks: 0}
            },
            2: {
              easy: {marks: 1, negative_marks: 0},
              medium: {marks: 2, negative_marks: 0},
              hard: {marks: 4, negative_marks: 0}
            },
            3: {
              easy: {marks: 1, negative_marks: 0},
              medium: {marks: 2, negative_marks: 0},
              hard: {marks: 4, negative_marks: 0}
            },
            4: {marks: 5, negative_marks: 0},
            5: {marks: 5 , negative_marks: 0}
          } 
        }
      })
      setSectionValues([...temporary]);
      setSelectedSubject('');
      fetchSubjects();
      fetchTopics();
    }
    else if (Object.keys(selectedCourse).length > 0) {
      fetchSubjects();
      if (!updateLoader) fetchTopics();
    }
  }, [selectedCourse]);


  useEffect(() => {
    console.log("Selected Subject is ", selectedSubject)
    if (!updateLoader) {
      if (selectedSubject.length > 0) {
        let temporary = sectionValues;
        if (selectedExamPattern.single_subject_exam) {
          temporary = temporary.map(element => {
            let assignedQuestions = processAssignedQuestionsPick(element, assignedQuestionsSchema);
            return { 
              ...element, 
              selectedSubject, 
              selectedTopic: '', 
              assignedQuestions, 
              startDate: '', 
              endDate: '', 
              availableTopics: [], 
              autoAssign: false, 
              easyAssigned: [], 
              mediumAssigned: [], 
              hardAssigned: [], 
              fileQuestionsAssigned: [],
              marks_allotment: {
                1: {
                  easy: {marks: 1, negative_marks: 0},
                  medium: {marks: 2, negative_marks: 0},
                  hard: {marks: 4, negative_marks: 0}
                },
                2: {
                  easy: {marks: 1, negative_marks: 0},
                  medium: {marks: 2, negative_marks: 0},
                  hard: {marks: 4, negative_marks: 0}
                },
                3: {
                  easy: {marks: 1, negative_marks: 0},
                  medium: {marks: 2, negative_marks: 0},
                  hard: {marks: 4, negative_marks: 0}
                },
                4: {marks: 5, negative_marks: 0},
                5: {marks: 5 , negative_marks: 0}
              } 
            }
          });

          setSectionValues([...temporary]);
        }
        else {
          let assignedQuestions = JSON.parse(JSON.stringify(assignedQuestionsSchema));

          let element = temporary[selectedSection - 1];
          temporary[selectedSection - 1].selectedTopic = '';
          temporary[selectedSection - 1].autoAssign = false;
          temporary[selectedSection - 1].easyAssigned = [];
          temporary[selectedSection - 1].mediumAssigned = [];
          temporary[selectedSection - 1].hardAssigned = [];
          temporary[selectedSection - 1].fileQuestionsAssigned = [];
          temporary[selectedSection - 1].marks_allotment = {
            1: {
              easy: {marks: 1, negative_marks: 0},
              medium: {marks: 2, negative_marks: 0},
              hard: {marks: 4, negative_marks: 0}
            },
            2: {
              easy: {marks: 1, negative_marks: 0},
              medium: {marks: 2, negative_marks: 0},
              hard: {marks: 4, negative_marks: 0}
            },
            3: {
              easy: {marks: 1, negative_marks: 0},
              medium: {marks: 2, negative_marks: 0},
              hard: {marks: 4, negative_marks: 0}
            },
            4: {marks: 5, negative_marks: 0},
            5: {marks: 5 , negative_marks: 0}
          } 
          setSectionValues([...temporary]);
        }
        fetchSubjects();
        fetchTopics();
      }
    }
    else {
      fetchSubjects();
      fetchTopics();
    }
  }, [selectedSubject]);


  useEffect(() => {
    console.log(selectedExamPattern, "section pattern")
    if (Object.keys(selectedExamPattern).length > 0 && updateLoader) {
      setUpdatedSections();
    }
    else if (isexamPatternSelected) {
      if (!updateLoader) {
        setselectedCourse({});
        let temporarySections = selectedExamPattern.sections;
        temporarySections = temporarySections.map((element, index) => {
          let assignedQuestions = processAssignedQuestionsPick(element, assignedQuestionsSchema);
          return {
            ...element,
            selectedSubject: '',
            selectedTopic: '',
            assignedQuestions,
            startDate: '',
            endDate: '',
            availableTopics: [],
            autoAssign: false,
            easyAssigned: [],
            mediumAssigned: [],
            hardAssigned: [],
            fileQuestionsAssigned: [],
            marks_allotment: {
              1: {
                easy: {marks: 1, negative_marks: 0},
                medium: {marks: 2, negative_marks: 0},
                hard: {marks: 4, negative_marks: 0}
              },
              2: {
                easy: {marks: 1, negative_marks: 0},
                medium: {marks: 2, negative_marks: 0},
                hard: {marks: 4, negative_marks: 0}
              },
              3: {
                easy: {marks: 1, negative_marks: 0},
                medium: {marks: 2, negative_marks: 0},
                hard: {marks: 4, negative_marks: 0}
              },
              4: {marks: 5, negative_marks: 0},
              5: {marks: 5, negative_marks: 0}
            } 
          }
        })
        setSectionValues([...temporarySections]);
      }
    }
    else {
      setExamFormLoader(false);
    }

    if (selectedExamPattern.login_required) {
      setbatchLoader(false);
      fetchBatch();
    }
    else {
      setBatch([]);
    }

  }, [selectedExamPattern]);

  useEffect(() => {
    console.log("validation canvas message is ", validationCanvasMessage)
  }, [validationCanvasMessage])

  useEffect(() => {
    setSectionValueLoader(false);
    fetchSubjects();
    fetchTopics();
    if(sectionValues.length) calculateQuestionsPickAvailability();
    if (Object.keys(props).length > 0) {
      setUpdateLoader(1);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (!updateLoader) {
      if (isexamPatternSelected) {
        let questionTypes = [];
        questionTypes = sectionValues.map(section => {
          return section.question_types[0].question_type
        });
        setNumberOfSections(selectedExamPattern.sections.length);
      }
    } else {
      fetchTopics();
    }

    if (sectionValues.length > 0) {
      if (!questionsAvailabilityPerPick?.selectedSection) {
        calculateQuestionsPickAvailability();
      }
      setSectionValueLoader(false)
    }
  }, [sectionValues]);


  // Fetch courses
  useEffect(() => {
    // Fetch courses from API and set options
    // Example: fetchCourses().then((courses) => setCourses(courses));
    setExamFormLoader(true);
  }, [examPatterns]);


  const getQuestionsList = async (complexity, selected_section, setQuestions = true) => {
    try {
      
      let types = sectionValues[selected_section - 1].question_types[0].question_type;
      let courseId = selectedCourse._id;
      let topicId = sectionValues[selected_section - 1].selectedTopic;
      let subjectId = sectionValues[selected_section - 1].selectedSubject;
      if( !(JSON.stringify([types]) && selectedFirm && courseId && subjectId && topicId) ) return;
      setQuestionsLoader(true);
      setCurrentComplexity(complexity);
      let allAvailableQuestions;
      if (complexity == '') {
        allAvailableQuestions = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?types=${JSON.stringify([types])}&client_id=${selectedFirm}&status=1&course_id=${courseId}&subject_id=${subjectId}&topic_id=${topicId}`);
      }
      else {
        allAvailableQuestions = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?types=${JSON.stringify([types])}&client_id=${selectedFirm}&status=1&course_id=${courseId}&subject_id=${subjectId}&topic_id=${topicId}&complexity=${complexity}`);
      }
      setQuestionsLoader(false);
      if (setQuestions) {
        if (allAvailableQuestions.status == 200) {
          setAvailableQuestions(allAvailableQuestions.data.questions);
        }
        else {
          // setQuestionsLoader(false);
          setAvailableQuestions([]);
        }
      } else {
        if (allAvailableQuestions.status == 200) {
          return allAvailableQuestions.data.questions;
        } else {
          return [];
        }
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  const setUpdatedSections = async () => {
    let temporarySections = props.selectedExam.sections;
    try {
      await new Promise((res, rej) => {
        if (Object.keys(selectedExamPattern).includes("sections") > 0 && selectedExamPattern.sections?.length === 0) res();
        selectedExamPattern.sections.forEach((section, index) => {
          temporarySections[index] = { ...temporarySections[index], ...section, availableTopics: [], autoAssign: false };
          if (index === selectedExamPattern.sections.length - 1) {
            res();
          }
        });

      });
    }
    catch (e) {
      console.log(e)
    }
    setSectionValues([...temporarySections]);
    setIsExamPatternSelected(true);

  }

  const fetchSubjects = async () => {
    if (selectedCourse && selectedCourse._id != undefined) {
      const result = await axios.get(process.env.REACT_APP_API_URL + `/subjects/list/${selectedCourse._id}`);
      if(result && result.data && (result.data).length > 0) {
        setSubjects(result.data);
        setNoTopicMessage('');
      } else {
        setNoTopicMessage('Subjects are not available for the selected Course.');
      }
      
    }
  }

  const fetchBatch = async () => {
    if (!selectedFirm) return;
    const result = await axios.get(process.env.REACT_APP_API_URL + `/batch/list/${selectedFirm}`);
    setBatch(result.data.result);
    setbatchLoader(true);
  }

  const fetchCourse = async () => {
    try {
      if (!selectedFirm) return;
      setcourseLoader(false);
      const res = await fetch(process.env.REACT_APP_API_URL + `/courses/list/${selectedFirm}`)
        .then(async data => {
          return await data.json();
        })
      setCourses([...res.result.courses]);
      setcourseLoader(true);
    }
    catch (err) {
      console.log(err)
    }
  }


  // Function to handle exam pattern selection
  const handleExamPatternChange = (event) => {
    let value = JSON.parse(event.target.value);
    if (value != '') {
      setSelectedExamPattern(value);
      setselectedCourse({});
      setAvailableQuestions([]);
      setSectionValues(value.sections);
      setIsExamPatternSelected(true);
    }
    else {
      setSelectedExamPattern({});
      setIsExamPatternSelected(false);
    }
  };

  // Function to handle course selection
  const handleCourseChange = async (event) => {
    if (event.target.value != '') {
      let course = JSON.parse(event.target.value);
      setselectedCourse(course);
    } else {
      setselectedCourse({});
      setSubjects([]);
    }
  };

  // Function to handle subject selection for a section
  const handleSubjectChange = (event, sectionIndex) => {
    const selected_subject = event.target.value;
    if (selected_subject.length > 0) {
      if (selectedExamPattern.single_subject_exam) {
        let temporary = sectionValues;
        temporary = sectionValues.map((element, index) => {
          return { ...element, selectedSubject: selected_subject }
        })
        setSectionValues([...temporary])
        setSelectedSubject(selected_subject);
        setIsSubjectSelected(true);
      } else {
        let temporary = sectionValues;
        temporary[selectedSection - 1].selectedSubject = selected_subject;
        setSectionValues([...temporary])
        setSelectedSubject(selected_subject);
        setIsSubjectSelected(true);
      }
    } else {
      let temporary = sectionValues;
      temporary[selectedSection - 1].selectedSubject = selected_subject;
      setSectionValues([...temporary])
      setSelectedSubject('');
      setIsSubjectSelected(false);
    }
  };

  // useEffect(() => {
  //   console.log("form pick", questionsAvailabilityPerPick)
  // }, [questionsAvailabilityPerPick])

  // Function to handle topic selection for a section
  const handleTopicChange = async (event) => {
    let temporary = sectionValues;
    let element = temporary[selectedSection - 1];
    temporary[selectedSection - 1].selectedTopic = event.target.value;
    // temporary[selectedSection - 1].assignedQuestions = assignedQuestions;
    temporary[selectedSection - 1].easyAssigned = [];
    temporary[selectedSection - 1].mediumAssigned = [];
    temporary[selectedSection - 1].hardAssigned = [];
    temporary[selectedSection - 1].fileQuestionsAssigned = [];
    // var availability = questionsAvailabilityPerPick;
    calculateQuestionsPickAvailability();
    setSectionValues([...temporary]);
  };

  const calculateQuestionsPickAvailability = async () => {
    setQuestionPickLoader(true);
    const currentSectionQuestionType = Number(sectionValues[selectedSection - 1].question_types[0].question_type);
    let currentSectionQuestionAvailability;
    switch(currentSectionQuestionType) {
      case 1:
      case 2:
      case 3: {
        currentSectionQuestionAvailability = { easy: null, medium: null, hard: null };
        await Promise.all(
          Object.keys(currentSectionQuestionAvailability).map(async complexity => {
            const isAvailable = await getQuestionsList(complexityValuePerQuestionType[complexity], selectedSection, false);
            currentSectionQuestionAvailability[complexity] = isAvailable;
    
          })
        );
        setQuestionsAvailabilityPerPick({...currentSectionQuestionAvailability, selectedSection});
        setQuestionPickLoader(false);
        break;
      };
      case 4: {
        const questions = await getQuestionsList('', selectedSection, false);
        currentSectionQuestionAvailability = {questions};
        setQuestionsAvailabilityPerPick({...currentSectionQuestionAvailability, selectedSection});
        setQuestionPickLoader(false);
        break;
      }
    }
    
  }


  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    handleSubmitHelper(event, {
      isSubmitting, setIsSubmitting, selectedExamPattern, selectedUserGroup,
      selectedCourse, user, sectionValues, examName, negativeMarks, questionsPerNegative,
      passMarks, selectedSubject, navigate, setActionLoader, props, examUtcOffset, selectedExamTimeZone,selectedFirm,
      setValidationCanvasMessage, setShowValidationCanvas
    });
  };

  const fetchExamPatterns = async () => {
    try {
      if (!selectedFirm) return ;
      setExamFormLoader(false);
      const response = await axios.get(process.env.REACT_APP_API_URL + `/examPattern/list/${selectedFirm}`);
      setExamPatterns([...response.data.examPatterns]);
    } catch (error) {
      console.error('Error fetching exam patterns:', error);
    }
  };

  const fetchTopics = async () => {
    try {
      if (sectionValues && sectionValues[selectedSection - 1] && sectionValues[selectedSection - 1].selectedSubject) {
        setTopicLoader(true);
        const topics = await axios.get(process.env.REACT_APP_API_URL + `/topics/list/${sectionValues[selectedSection - 1].selectedSubject}`);
        if(topics && topics.data && topics.data.result && (topics.data.result).length > 0) {
          sectionValues[selectedSection - 1].availableTopics = topics.data.result;
          setTopics([...topics.data.result]);
          setNoTopicMessage('');
        } else {
          setNoTopicMessage('Topics are not available for the selected Subject.');
        }
        
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  const handleSectionChange = (index) => {
    setSelectedSection(index)
  }

  const handleDateChange = (e) => {
    let temporary = sectionValues;
    temporary[selectedSection - 1][e.target.name] = e.target.value;
    setSectionValues([...temporary]);
  }

  const handleAssignRadio = async (e) => {
    if (!sectionValues[selectedSection - 1].selectedTopic.length) {
      playSound('NOTICE');
      alert("Please select a topic!");
      return;
    }

    setSectionValueLoader(true);
    setQuestionPickLoader(true);

    let autoAssign = e.target.value === 'auto';
    let currentSection = sectionValues[selectedSection - 1];


    if (autoAssign) {
      await processAssignedQuestions(autoAssign, currentSection.assignedQuestions[0], selectedSection, selectedCourse, sectionValues, setSectionValues, user, selectedFirm, questionsAvailabilityPerPick);
    } else {
      let temporary = sectionValues;
      temporary[selectedSection - 1].autoAssign = false;
      setSectionValues([...temporary]);
    }

    setQuestionPickLoader(false);
    setSectionValueLoader(false);
  };

  const emailsFileUpload = (e) => {
    emailsFileUploadHelper(e, setFileData);
  };

  const checkTableAvailability = () => {
    try {
      const qType = Number(sectionValues[selectedSection - 1].question_types[0].question_type);
      switch(qType) {
        case 1:
        case 2:
        case 3: {
          return questionsAvailabilityPerPick.easy && questionsAvailabilityPerPick.medium && questionsAvailabilityPerPick.hard;
        };
        case 4: {
          return questionsAvailabilityPerPick.questions != undefined;
        }
      }
    } catch(e) {
      console.error(e);
      return false;
    }
  }


  if (examFormLoader && isFirmsLoaded)
    return (

      <div className='create-form-page-main-container mt-3'>
        <div className='page-main-form-container-main-header'>{Object.keys(props).length > 0 ? "Update Exam" : "Create Exam"}</div>
        <form onSubmit={handleSubmit} className="create-exam-form mt-4">
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-elements-container mb-2'>
                <div className='label-area width-15'>
                  <label className='text-right'>Exam Name:</label>
                </div>
                <div className='form-info-entry-area width-85'>
                  <input type="text" value={examName}
                    onChange={(e) => setExamName(e.target.value)} required />
                </div>
              </div>
            </div>
          </div>
          {((user.user_type == 'superAdmin' && firmsList.length > 0 && !Object.keys(props).length)) &&
            <>
              {!Object.keys(props).length && user.user_type == 'superAdmin' &&
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-elements-container mb-2'>
                      <div className='label-area width-35'>
                        <label className='text-right'>Select Firm:</label>
                      </div>
                      <div className='form-info-entry-area width-65'>
                        <select onChange={(e) => { setSelectedFirm(e.target.value) }} value={selectedFirm} required>
                          <option value='' selected={Object.keys(selectedCourse).length == 0}>---Select a Firm---</option>
                          {firmsList.map((firm, index) => {
                            return <option value={firm._id} key={index} >{firm.firm_name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div></div>
              }
            </>
          }

          {((user.user_type == 'superAdmin' && firmsList.length === 0 && !Object.keys(props).length)) &&
            <>
              {!Object.keys(props).length && user.user_type == 'superAdmin' &&
                <div className='h-100 w-100'>Unable to fetch firms list</div>
              }
            </>
          }
          {selectedFirm &&
            <>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-elements-container mb-2'>
                    <div className='label-area width-35'>
                      <label className='text-right'>Select Exam Pattern:</label>
                    </div>
                    <div className='form-info-entry-area width-65'>
                      <select name="exam_pattern" required 
                      onChange={(e) => { 
                        if (e.target.value != '') { 
                          handleExamPatternChange(e) 
                        } 
                        else { 
                          setIsExamPatternSelected(false); 
                          setselectedCourse({}); 
                          // navigate("/exams/create") 
                        } 
                      }}>
                        {/* Options for exam patterns */}
                        <option value=''>--- Select an exam Pattern ---</option>
                        {examPatterns.map((element, index) => {
                          return <option key={index} value={JSON.stringify(element)} selected={selectedExamPattern._id === element._id}>{element.exam_pattern_name}</option>
                        })}
                      </select>
                    </div>
                  </div>

                </div>

                <div className='col-md-6'>
                  {isexamPatternSelected && selectedExamPattern.login_required && (
                    <div className='form-elements-container mb-2'>
                      <div className='label-area width-30'>
                        <label className='text-right'>Select Batch:</label>
                      </div>
                      <div className='form-info-entry-area width-70'>
                        <select onChange={(e) => { if (e.target.value != '') { setSelectedUserGroup(e.target.value) } }} required>
                          {/* Options for batches */}
                          <option value='' >---Select a batch---</option>
                          {batchLoader && Batch.map((batch, index) => {
                            if (!Object.keys(props).includes("selectedExam")) {
                              return <option value={batch.group._id} key={index}>{batch.group.name}</option>
                            }
                            else {
                              return <option value={batch.group._id} key={index} selected={props.selectedExam.user_group === batch.group._id}>{batch.group.name}</option>
                            }
                          })}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-elements-container mb-2'>
                    <div className='label-area width-35'>
                      <label className='text-right'>Select Course:</label>
                    </div>
                    <div className='form-info-entry-area width-65'>
                    <select onChange={handleCourseChange} required>
                        <option value='' selected={Object.keys(selectedCourse).length == 0}>---Select a Course---</option>
                        {courseLoader && courses.map((course, index) => {
                          return <option value={JSON.stringify(course)} key={index} selected={course._id === selectedCourse._id}>{course.course_name}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  {selectedExamPattern.single_subject_exam && (
                    <div className='form-elements-container mb-2'>
                      <div className='label-area width-30'>
                        <label className='text-right'>Select Subject: </label>
                      </div>
                      <div className='form-info-entry-area width-70'>
                        <select onChange={(e) => { handleSubjectChange(e) }} value={selectedSubject || ''}>
                          <option value=''  disabled>---Select a Subject ---</option>
                          {subjects.length > 0 && subjects.map((subject, index) => {
                            return <option key={index} value={subject._id} >{subject.subject_name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row align-items-center'>
                <div className='col-md-12'>
                  {isexamPatternSelected && !selectedExamPattern.login_required && (
                    <div className='row align-items-center'>
                      <div className='col-md-2'>
                        <div className='form-elements-container mb-2'>
                          <div class="label-area ">
                            <label className='text-right'>Examinee Email(s): </label>
                          </div>

                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-elements-container mb-2'>
                          <textarea type="email" className='emails_text_area'
                            defaultValue={Object.keys(props).includes("selectedExam") ? props.selectedExam.examinee_email.join(",") : ""}
                            required multiple={true} id='examineeEmails'>
                          </textarea>
                        </div>
                      </div>
                      <div className='col-md-1 text-center'>
                        <span><strong>OR</strong></span>
                      </div>
                      <div className='col-md-5'>
                        <div className='form-elements-container mb-2'>
                          <table className='upload_download_container'>
                            <tr>
                              <th>
                                <a className='file_download' title='Download Sample Template' download="sampleEmailsUploadFormat.xlsx" href={sampleUploadEmailsFormat}>
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                    </svg>
                                  </span>Check Instructions for uploading the emails format.
                                </a>
                              </th>
                            </tr>
                            <tr>
                              <td className='border-0 p-0 pt-2 ' style={{ textAlign: 'left' }}>
                                <input type="file" accept=".csv" onChange={emailsFileUpload} />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                    </div>
                  )}
                </div>
              </div>


              {/*----------------------------- section names container=-----------------------  */}
              {isexamPatternSelected && <div className='create-exam-section-container w-100 d-flex align-items-stretch'>
                <div className='w-25 d-flex flex-column create-exam-section-container-left' style={{ overflowY: 'auto' }}>
                  {sectionValues.map((element, index) => {
                    return <div key={index} className={index == (selectedSection - 1) ? 'p-3 text-center section-selected-item ' : 'p-3 border text-center section-mouse-over'} onClick={() => { setSelectedSection(index + 1); }}>
                      {element.section_name}
                    </div>
                  })}
                </div>
                {/* ----------------------------section editor container ---------- */}
                {!sectionValueLoader &&
                  <div className=' w-100 p-3 d-flex flex-column create-exam-section-container-right' style={{ overflow: 'auto', background: '#e4e9ed' }}>
                    <p className='question-type-details'>Question Type:
                      <i>{questionTypesToName[sectionValues[selectedSection - 1].question_types[0].question_type]}</i>
                    </p>
                    {selectedExamPattern.section_wise_timings &&

                      // <div className='d-flex align-items-center gap-2 p-3 ps-0'>
                      //   <label className='text-center'>
                      //   Start Date <br/>
                      //   <input type="datetime-local" name="startDate" value={sectionValues[selectedSection - 1].startDate} onChange={(e)=>{if(e.target.value.length > 0) {handleDateChange(e)}}}/>
                      //   </label>
                      //   <label  className='text-center'>
                      //   End Date <br/>
                      //   <input type="datetime-local"  name="endDate"  value={sectionValues[selectedSection - 1].endDate} onChange={(e)=>{if(e.target.value.length > 0) {handleDateChange(e)}}}/>
                      //   </label>
                      // </div>

                      <div className='row mb-3 form-elements-container'>
                        <div className='col-md-12'>
                          <div className='row mb-3'>
                            <div className='col-md-2'>
                              <div className='label-area width-auto'>
                                <label className='text-right'>Start Date</label>
                              </div>
                            </div>
                            <div className='col-md-4'>
                              <div className='form-info-entry-area width-auto'>
                                {Object.keys(props).includes("selectedExam") && <input type="datetime-local" name="startDate" value={getDefaultDateTime(sectionValues[selectedSection - 1].startDate)} onChange={(e) => { if (e.target.value.length > 0) { handleDateChange(e) } }} />}
                                {!Object.keys(props).includes("selectedExam") && <input type="datetime-local" name="startDate" value={sectionValues[selectedSection - 1].startDate} onChange={(e) => { if (e.target.value.length > 0) { handleDateChange(e) } }} />}
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-2'>
                              <div className='label-area width-auto'>
                                <label className='text-right'>End Date</label>
                              </div>
                            </div>
                            <div className='col-md-4'>
                              <div className='form-info-entry-area width-auto'>
                                {Object.keys(props).includes("selectedExam") && <input type="datetime-local" name="endDate" value={getDefaultDateTime(sectionValues[selectedSection - 1].endDate)} onChange={(e) => { if (e.target.value.length > 0) { handleDateChange(e) } }} />}
                                {!Object.keys(props).includes("selectedExam") && <input type="datetime-local" name="endDate" value={sectionValues[selectedSection - 1].endDate} onChange={(e) => { if (e.target.value.length > 0) { handleDateChange(e) } }} />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {!selectedExamPattern.single_subject_exam && (
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-elements-container mb-2'>
                            <div className='label-area width-35'>
                              <label className='text-right' style={{ wordBreak: 'break-all' }}>Select Subject:</label>
                            </div>
                            <div className='form-info-entry-area width-65'>
                              <select onChange={(e) => { handleSubjectChange(e) }}>
                                <option value='' selected={sectionValues[selectedSection - 1].selectedSubject == ''}>---Select a Subject ---</option>
                                {subjects.length > 0 && subjects.map((subject, index) => {
                                  return <option key={index} value={subject._id} selected={sectionValues[selectedSection - 1].selectedSubject == subject._id}>{subject.subject_name}</option>
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {sectionValues[selectedSection - 1].selectedSubject?.length > 0 && !topicLoader &&
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-elements-container mb-2'>
                            <div className='label-area width-35'>
                              <label className='text-right' style={{ wordBreak: 'break-all' }}>
                                Select Topic:
                              </label>
                            </div>
                            <div className='form-info-entry-area width-65'>
                              <select onChange={(e) => { handleTopicChange(e) }}>
                                <option value='' selected={sectionValues[selectedSection - 1].selectedTopic == ''}>---Select a Topic ---</option>
                                {topics.map((element, index) => {
                                  return <option key={index} value={element._id} selected={sectionValues[selectedSection - 1].selectedTopic == element._id}>{element.topic_name}</option>
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {!sectionValues[selectedSection - 1].selectedTopic &&
                      <div className='d-flex justify-content-center m-auto topic-not-selected'>
                        { noTopicMessage ? (<>
                          <span>{noTopicMessage}</span>
                        </>) : (<>
                          {sectionValues[selectedSection - 1].selectedSubject?.length <= 0 && (
                            <span>Please select a course & subject, to populate the topics list.</span>
                          )}
                          {sectionValues[selectedSection - 1].selectedSubject?.length > 0 && (
                            <span>Please select a topic to assign the question(s) to exam.</span>
                          )}
                        </>) }
                        
                      </div>
                    }
                    {!sectionValueLoader && sectionValues[selectedSection - 1].selectedTopic &&
                      <div className='d-flex align-items-center mb-3 gap-4'>
                        <label className='d-flex align-items-center gap-2'>
                          <input type="radio" name="assignType" value="auto"
                            checked={sectionValues[selectedSection - 1].autoAssign === true}
                            onChange={(e) => { handleAssignRadio(e) }} />
                          <span className=''>Auto assign Questions</span>
                        </label>
                        <label className='d-flex align-items-center gap-2'>
                          <input type="radio" name="assignType" value="manual"
                            checked={sectionValues[selectedSection - 1].autoAssign === false}
                            onChange={(e) => { handleAssignRadio(e) }} />
                          <span className=''>Manually assign Questions</span>
                        </label>
                      </div>
                    }
                    
                    {sectionValues && sectionValues[selectedSection - 1].selectedTopic &&
                      (checkTableAvailability() ?
                      <QuestionComplexityTable
                        sectionValues={sectionValues}
                        setSectionValues={setSectionValues}
                        sectionValueLoader={sectionValueLoader}
                        questionPickLoader={questionPickLoader}
                        setQuestionsLoader={setQuestionsLoader}
                        setCurrentComplexity={setCurrentComplexity}
                        selectedSection={selectedSection}
                        selectedCourse={selectedCourse}
                        setAvailableQuestions={setAvailableQuestions}
                        questionsAvailabilityPerPick={questionsAvailabilityPerPick}
                        selectedFirm={selectedFirm}
                        setShowAssignModal={setShowAssignModal}
                      /> 
                      : 
                      <div className='d-flex align-items-center justify-content-center h-100 w-100'><SpinnerLoader/></div>)
                    }

                  </div>
                }
              </div>}
              {/* --------------------------------------------------------------------------------- */}
              <Offcanvas show={actionLoader} placement='end' style={{ height: 'fit-content' }}>
                <Offcanvas.Body>
                  {actionLoaderMessage}
                </Offcanvas.Body>
              </Offcanvas>
              <Offcanvas show={showValidationCanvas} placement='end' style={{ height: 'fit-content' }}>
                <Offcanvas.Body className='border border-danger'>
                  <>{validationCanvasMessage}</>
                </Offcanvas.Body>
              </Offcanvas>
              {/* -----------------------------------------------assign questions Modal---------------------- */}
               
               <AssignQuestionsModal
               sectionValues={sectionValues}
               selectedSection={selectedSection}
               setSelectionQuestions={setSelectionQuestions}
               selectedQuestions={selectedQuestions}
               currentComplexity={currentComplexity || 'N/A'}
               setSectionValues={setSectionValues}
               questionsLoader={questionsLoader}
               isexamPatternSelected={isexamPatternSelected}
               availableQuestions={availableQuestions}
               questionsAvailabilityPerPick={questionsAvailabilityPerPick}
               showAssignModal={showAssignModal}
               setShowAssignModal={setShowAssignModal}
             />
              {/* --------------------------------------------------------------------------- */}
              {isexamPatternSelected && !selectedExamPattern.section_wise_timings &&
                <div className='form-elements-container mt-3'>

                  <div className='row mb-3'>
                    <div className='row d-flex align-items-center'>
                      <div className='label-area width-auto col-2 py-auto'>
                        <label>Select TimeZone: </label>
                      </div>
                      <div className='col-4'>
                        <TimeZoneDropdown className="form-info-entry-area width-auto"
                          selectedExamTimeZone={selectedExamTimeZone}
                          setselectedExamTimeZone={setselectedExamTimeZone}
                          examUtcOffset={examUtcOffset}
                          setExamUtcOffset={setExamUtcOffset}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-md-2'>
                      <div className='label-area width-auto'>
                        <label>Start Date and Time: </label>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-info-entry-area width-auto'>
                        {
                          Object.keys(props).includes("selectedExam") ?
                            <input type='datetime-local' defaultValue={getDefaultDateTime(props.selectedExam.startDate)}
                              onChange={(e) => { setExamStartDate(getDefaultDateTime(e.target.value)); e.target.blur(); }}
                              id='startdatetime'
                              required
                            /> :
                            <input type='datetime-local' id='startdatetime' 
                              onChange={(e) => { setExamStartDate(getDefaultDateTime(e.target.value)); e.target.blur(); }}
                              required
                            />
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='label-area width-auto me-2'>
                        <label>Negative Mark(s): </label>
                      </div>
                      <div className='form-info-entry-area width-20 me-2'>
                        <input type='number' className='text-center' value={negativeMarks} onChange={(e) => setNegativeMarks(e.target.value)} required />
                      </div>

                      <div className='label-area width-auto me-1'>
                        <label>per </label>
                      </div>
                      <div className='form-info-entry-area width-20 me-2'>
                        <input type='number' className=' text-center' value={questionsPerNegative} onChange={(e) => setQuestionsPerNegative(e.target.value)} required />
                      </div>

                      <div className='label-area width-auto'>
                        <label>Question(s).</label>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-2'>
                      <div className='label-area width-auto'>
                        <label>End Date and Time:</label>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-info-entry-area width-auto'>
                        {
                          Object.keys(props).includes("selectedExam") ?
                            <input type='datetime-local' value={examEndDate} min={`${examStartDate}`} 
                            onChange={(e) => { 
                              const selectedEndDate = getDefaultDateTime(e.target.value);
                              if (new Date(selectedEndDate) > new Date(examStartDate)) {
                                setExamEndDate(selectedEndDate);  
                              } else {
                                playSound('ERROR');
                                alert('End date and time must be greater than the start date and time');
                              }
                              e.target.blur();
                            }} id='enddatetime' required /> :
                            <input type='datetime-local' id='enddatetime' value={examEndDate} min={`${examStartDate}`} 
                            onChange={(e) => { 
                              const selectedEndDate = getDefaultDateTime(e.target.value);
                              if (new Date(selectedEndDate) > new Date(examStartDate)) {
                                setExamEndDate(selectedEndDate);  
                              } else {
                                playSound('ERROR');
                                alert('End date and time must be greater than the start date and time');
                              }
                              e.target.blur(); 
                            }} required />
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='label-area width-auto me-2'>
                        <label>Pass Marks: </label>
                      </div>
                      <div className='form-info-entry-area width-25 me-2'>
                        <input type='number' className='text-center' value={passMarks} onChange={(e) => setPassMarks(e.target.value)} id="pass_marks" />
                      </div>
                    </div>
                  </div>
                </div>
              }

              <div className="col-12 mt-3"><hr className="form-btn-seperator" /></div>

              <div className='form-elements-container w-100 gap-3'>

                
                 <div className='col-12 gap-3 text-center'>
                    <button type="submit" disabled={isSubmitting || actionLoader} className='app-main-form-btn' >{Object.keys(props).length > 0 ? "Update Exam" : "Create Exam"}</button>
                    <button className='nexus-button-dark-outline mar-L-10' onClick={() => {navigate("/exams")}}>Cancel</button>
                    {isexamPatternSelected &&
                      <span className='float-end total-marks'>Total Marks: {selectedExamPattern.total_marks} </span>
                    }
                </div>
              </div></>
          }
        </form>
        {/*--------------------------- Successful exam delete modal message -----------------------------*/}
        <button type="button" className="btn btn-primary d-none" id="examCreateSuccessMsgToggler" data-bs-toggle="modal" data-bs-target="#examCreateMsg"></button>
        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="examCreateMsg" tabIndex="-1" role="dialog" aria-labelledby="examCreateMsgLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ left: '100%' }}>
              <div className="modal-body d-flex align-items-center bg-white text-success justify-content-between">
                <div>Exam created / updated successfully!</div>
                <div className='btn p-0 px-1' data-bs-dismiss="modal" id="examCreateSuccessClose" aria-label="Close"> &times;</div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------------------*/}
      </div>
    );
  else return (<div className="h-100 w-100 d-flex justify-content-center align-items-center">
    <SpinnerLoader />
  </div>)

}