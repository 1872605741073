// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comprehensives-form > * {
  margin-bottom: 0.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Comprehensives/comprehensives.scss"],"names":[],"mappings":"AAES;EACG,gCAAA;AADZ","sourcesContent":[".comprehensives {\n    &-form {\n         > * {\n            margin-bottom: 0.5rem !important;\n         }\n\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
