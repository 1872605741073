import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Context from '../../user_details';
import SpinnerLoader from '../../helpers/spinner-loader';
import { Modal } from 'react-bootstrap';
import MarksDefinitionModal from './marksDefinitionModal';

const QuestionComplexityTable = ({
  sectionValues,
  setSectionValues, 
  sectionValueLoader, 
  questionPickLoader, 
  setQuestionsLoader, 
  setCurrentComplexity, 
  selectedSection, 
  selectedCourse, 
  setAvailableQuestions, 
  questionsAvailabilityPerPick,
  selectedFirm, 
  setShowAssignModal
}) => {
    const { user } = useContext(Context);
    const [insufficientPickMessage, setInsufficientPickMessage] = useState("Insufficient number of questions to pick");
    const [pickValidationLoader, setPickValidationLoader] = useState(false);
    const complexityContainingQuestionsTypes = [1,2,3];
    const [showMarksModal, setShowMarksModal] = useState(false);
    
    const getAssignedQuestionsNumber = (complexity = null) => {
        let questionType = sectionValues[selectedSection - 1].question_types[0].question_type;
        let assignedQuestions = sectionValues[selectedSection - 1].assignedQuestions;
        if (complexity) {
          return Number(assignedQuestions[0][questionType][complexity].question_ids.length)
        }
        else {
          return Number(assignedQuestions[0][questionType].question_ids.length)
        }
    }

    const verifyAssignedQuestionsNumber = () => {
        const targetSection = sectionValues[selectedSection - 1];
        return targetSection.assignedQuestions && targetSection.selectedSubject.length > 0 && targetSection.selectedTopic.length > 0 && Object.keys(selectedCourse).length > 0;
    }

    const getQuestionsList = async (complexity) => {
        try {
          setQuestionsLoader(true);
          setCurrentComplexity(complexity);
          let types = sectionValues[selectedSection - 1].question_types[0].question_type;
          let courseId = selectedCourse._id;
          let topicId = sectionValues[selectedSection - 1].selectedTopic;
          let subjectId = sectionValues[selectedSection - 1].selectedSubject;
          let allAvailableQuestions;
          if (complexity == '') {
            // for comprehensive and file upload ----- may change later
            // allAvailableQuestions = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?types=${JSON.stringify([types])}&client_id=${selectedFirm}&status=1&course_id=${courseId}&subject_id=${subjectId}&topic_id=${topicId}`);
            allAvailableQuestions = {data: { questions: questionsAvailabilityPerPick.questions}};
          } else {
            // allAvailableQuestions = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?types=${JSON.stringify([types])}&client_id=${selectedFirm}&status=1&course_id=${courseId}&subject_id=${subjectId}&topic_id=${topicId}&complexity=${complexity}`);
            allAvailableQuestions = {data: { questions: questionsAvailabilityPerPick[complexity]}};
          }
    
          setShowAssignModal(true);
          setQuestionsLoader(false);
          setAvailableQuestions(allAvailableQuestions.data.questions);
        }
        catch (e) {
          console.log(e)
          setQuestionsLoader(false);
          setAvailableQuestions([]);
        }
    }

   if (pickValidationLoader) {
    return (
      <div className='d-flex ailgn-items-center justify-content-center w-100 h-100'><SpinnerLoader/></div>
    )
   } else {
    return (
      <>
        <div className='d-inline-flex py-2 justify-content-start align-items-center gap-4'>
          <p className='m-0 fw-medium nexus-color-primary-note'>Marks Allotment: </p>
          <button className='nexus-button-primary-dark fw-medium d-flex align-items-center justify-content-center gap-2' onClick={
            (e) => {
              e.preventDefault();
              setShowMarksModal(prev => !prev);
            }
          }><i class="bi bi-card-checklist fs-5"></i> Marks Allotment</button>
        </div>
        <table className='question-complexity-table'>
            <tbody>
                <tr>
                <th className=''> {complexityContainingQuestionsTypes.includes(sectionValues[selectedSection - 1].question_types[0].question_type) ? "Question Complexity" : ""}</th>
                <th className=" fw-bold text-white">Pick</th>
                <th className=" fw-bold text-white">Assigned</th>
                </tr>
            </tbody>
            {!sectionValueLoader && 
              !questionPickLoader && 
              (() => {
                switch(sectionValues[selectedSection - 1].question_types[0].question_type) {
                  case 1:
                  case 2:
                  case 3: {
                    return (
                      <tbody>
                      <tr > 
                        {/* Complexity */}
                          <td>Easy</td>
        
                        {/*Pick  */}
                          <td>{sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions}</td>
        
                        {/* Assigned */}
                          <td>
                            {verifyAssignedQuestionsNumber() ? getAssignedQuestionsNumber(1) : 0}
        
                            {sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions > 0 && (
                              <>
                                {!sectionValues[selectedSection - 1].autoAssign && questionsAvailabilityPerPick.easy.length >= sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions &&
                                  <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList(1)}>assign</div>
                                }
                              </>
                            )}
                          
                            {questionsAvailabilityPerPick.easy.length < sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions && <p className='text-danger '>{insufficientPickMessage}</p>}
                          </td>
                      </tr>
                      <tr className=''>
                        {/* Complexity */}
                          <td>Medium</td>
        
                          {/*Pick  */}
                          <td>{sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions}</td>
        
                          {/* Assigned */}
                          <td>
                            {sectionValues[selectedSection - 1].assignedQuestions ? getAssignedQuestionsNumber(2) : 0}
        
                            {sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions > 0 && (
                              <>
                                {!sectionValues[selectedSection - 1].autoAssign && questionsAvailabilityPerPick.medium.length >= sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions &&
                                  <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList(2)} >
                                      assign
                                  </div>
                                }
                              </>
                            )}
                          {questionsAvailabilityPerPick.medium.length < sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions&& <p className='text-danger '>{insufficientPickMessage}</p>}
                          </td>
                      </tr>
                      <tr className=''>
                          {/* Complexity */}
                          <td>Hard</td>
        
                          {/* Pick */}
                          <td>{sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions}</td>
                          
                          {/* Assigned */}
                          <td>
                            {sectionValues[selectedSection - 1].assignedQuestions ? getAssignedQuestionsNumber(3) : 0}
                            {sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions > 0 && (
                              <>
                                {!sectionValues[selectedSection - 1].autoAssign && questionsAvailabilityPerPick.hard.length >= sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions &&
                                  <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList(3)} >assign</div>
                                }
                              </>
                            )}
                            
                          {questionsAvailabilityPerPick.hard.length < sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions && <p className='text-danger '>{insufficientPickMessage}</p>}
                        </td>
                          
                      </tr>
                      </tbody>
                    )
                  }
                  case 4: {
                    return (
                      <tbody>
                        <tr >
                          {/* Complexity */}
                          <td>Comprehensives</td>

                          {/*Pick  */}
                          <td>{sectionValues[selectedSection - 1].question_types[0].no_of_questions}</td>

                          {/* Assigned */}
                          <td>
                            {verifyAssignedQuestionsNumber() ? getAssignedQuestionsNumber() : 0}

                            {sectionValues[selectedSection - 1].question_types[0].no_of_questions > 0 && (
                              <>
                                {!sectionValues[selectedSection - 1].autoAssign && questionsAvailabilityPerPick.questions.length >= sectionValues[selectedSection - 1].question_types[0].no_of_questions &&
                                  <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList()}>assign</div>
                                }
                              </>
                            )}

                            {questionsAvailabilityPerPick.questions.length < sectionValues[selectedSection - 1].question_types[0].no_of_questions && <p className='text-danger '>{insufficientPickMessage}</p>}
                          </td>
                        </tr>
                      </tbody>
                    )
                  }
                }
              })()

            }

            {sectionValues[selectedSection - 1].question_types[0].question_type == 5
                &&
                <tbody>
                <tr className=''>
                    <td> Minimum number of questions</td>
                    <td>{sectionValues[selectedSection - 1].question_types[0].no_of_questions}</td>
                    <td>{sectionValues[selectedSection - 1].assignedQuestions && getAssignedQuestionsNumber()}{!sectionValues[selectedSection - 1].autoAssign &&
                    <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList('')}>assign</div>}</td>
                </tr>
                </tbody>
            }
        </table>
        <Modal show={showMarksModal} className='w-100 marks-modal-dialog' >
          {/* <Modal.Header>
            <h4 className='nexus-color-primary-note'>{sectionValues[selectedSection - 1].section_name}</h4>
          </Modal.Header> */}
          <Modal.Body className='p-0 d-flex flex-column'  >
            <MarksDefinitionModal Qtype={sectionValues[selectedSection - 1].question_types[0].question_type} sectionValues={sectionValues} setSectionValues={setSectionValues} selectedSection={selectedSection} setShowMarksModal={setShowMarksModal} />
            
          </Modal.Body>
          {/* <Modal.Footer className='p-2 d-flex align-items-center justify-content-end'>
            <button className='nexus-button-danger' onClick={() => { setShowMarksModal(false) }}>Close</button>
          </Modal.Footer> */}
        </Modal>
        
      </>
  );
   }
}

export default QuestionComplexityTable;