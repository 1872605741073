import { useState, useEffect, useContext } from "react";
import {useNavigate, useParams} from 'react-router-dom';
import Context from "../../user_details";
import "./comprehensives.scss";
import SpinnerLoader from "../../helpers/spinner-loader";
import axios from "axios";
import { Modal, Offcanvas } from "react-bootstrap";
import { playSound } from '../../helpers/otherSounds';

export default function ComprehensiveForm({ formType}) {
    const navigate= useNavigate();
    const params= useParams();
    const { user } = useContext(Context);
    const [comprehensiveForm, setComprehensiveForm] = useState({
        client_id: (user.user_type == 'superAdmin')? "" : user.firm_id ,
        title: "",
        course_id: "",
        subject_id: "",
        topic_id: "",
        content: ""
    });
    const [loader, setLoader] = useState(false);
    const [firmsList, setFirmsList] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [subjectsList, setSubjectsList] = useState([]);
    const [topicsList, setTopicsList] = useState([]);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [offcanvasMsg, setOffcanvasMessage] = useState("");
    const [offcanvasTimer, setOffcanvasTimer] = useState(2000);

    useEffect(() => {
        console.log("these are form values", comprehensiveForm)
    }, [comprehensiveForm]);

    useEffect(() => {
       switch(formType) {
        case 'Create': {
            if (user.user_type === 'superAdmin') {
                fetchAllFirms();
                break;
            } else {
                fetchCourses(comprehensiveForm.client_id);
            }
            break;
        };
        case 'Edit': 
        case 'View': {
            generateForm();
            break;
        }
       }
    }, []);

    const generateForm = () => {
       try {
            fetchComprehension().then(data => {
                if(data.success) {
                    fetchFirmById(data.result.client_id);
                    fetchCourses(data.result.client_id);
                    fetchSubjects(data.result.course_id);
                    fetchTopics(data.result.subject_id);
                } else {
                    setOffcanvasMessage(<div className="border border-2 border-danger fw-medium text-danger bg-white p-3 text-center">
                        Unable to fetch details
                    </div>);
                    setShowOffcanvas(true);
                    setTimeout(() => {
                        setShowOffcanvas(false);
                        navigate("/comprehensives");
                    }, offcanvasTimer)
                }
            })
            
       } catch(e) {

       }
    }

    const fetchComprehension = () => {
        return new Promise(async(res,rej) => {
            try {
                setLoader(true);
                const {id} = params;
                const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/qb/comprehensives/read/${id}`);
                if(!data.success) throw new Error();
                setComprehensiveForm(data.result);
                res({success: true, result: data.result})
            }
            catch(e) {
                console.error(e);
                res({success: false, result: null})
            } finally {
                setLoader(false);
            }
        })
    }

    const fetchFirmById = async (firm_id) => {
        try {
            setLoader(true);
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/read/${firm_id}`);
            if (data.success) {
                setFirmsList([data.result]);
            } else {
                throw new Error("Unable To Fetch Client Details")
            }
        } catch (e) {
            setFirmsList([]);
            console.error(e);
        } finally {
            setLoader(false);
        }
    }

    const fetchAllFirms = async () => {
        try {
            setLoader(true);
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
            if (data.success) {
                setFirmsList(data.results);
            } else {
                throw new Error("Unable To Fetch Client Details")
            }
        } catch (e) {
            setFirmsList([]);
            console.error(e);
        } finally {
            setLoader(false);
        }
    }

    const handleFormChanges = async (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "client_id": {
                await fetchCourses(value);
                setComprehensiveForm(prev => ({
                    ...prev,
                    [name]: value,
                    course_id: "",
                    subject_id: "",
                    topic_id: ""
                }));
                break;
            };
            case "course_id": {
                await fetchSubjects(value);
                setComprehensiveForm(prev => ({
                    ...prev,
                    [name]: value,
                    subject_id: "",
                    topic_id: ""
                }));
                break;
            };
            case "subject_id": {
                await fetchTopics(value);
                setComprehensiveForm(prev => ({
                    ...prev,
                    [name]: value,
                    topic_id: ""
                }));
                break;
            };
            default: {
                setComprehensiveForm(prev => ({
                    ...prev,
                    [name]: value,
                }));
                break;
            }
        }

    }

    const fetchCourses = async (firm_id) => {
        try {
            setLoader(true);
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/qb/courses/clientId/${firm_id}`);
            setCoursesList(data.courses);

        } catch (e) {
            console.error(e);
            setCoursesList([]);
        } finally {
            setLoader(false)
        }
    }

    const fetchSubjects = async (course_id) => {
        try {
            setLoader(true);
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/qb/subjects/search/${course_id}/${comprehensiveForm.client_id}`);
            setSubjectsList(data);

        } catch (e) {
            console.error(e);
            setSubjectsList([]);
        } finally {
            setLoader(false)
        }
    }

    const fetchTopics = async (subject_id) => {
        try {
            setLoader(true);
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/qb/topics/search/${subject_id}/${comprehensiveForm.client_id}`);
            if (data.success) {
                setTopicsList(data.result);
            } else {
                throw new Error("Unable To Fetch Topics")
            }

        } catch (e) {
            console.error(e);
            setTopicsList([]);
        } finally {
            setLoader(false)
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const {id} = params;
        var requestEndpoint = `${process.env.REACT_APP_API_URL}`;
        if(requestEndpoint.endsWith('/')) {
            requestEndpoint = requestEndpoint.substring(0,requestEndpoint.length-1);
        }
        var payload = {
                        ...comprehensiveForm, 
                        ...((() => {
                            switch(formType){
                                case 'Create': {
                                    requestEndpoint += `/qb/comprehensives/create`;
                                    return {created: {by: user._id}}
                                };
                                case 'Edit': {
                                    requestEndpoint += `/qb/comprehensives/update/${id}`;
                                    return {updated: {by: user._id}}
                                }
                            }
                        })())
                    }
        
        try {
            var response;
            if(formType === 'Create') {
                response = await axios.post(requestEndpoint, payload);
            } else {
                response = await axios.patch(requestEndpoint, payload);
            }
            if(!response.data.success) throw new Error(`Failed to ${formType} Comprehensive`);
            setOffcanvasMessage(<div className="p-3 border border-2 border-success text-success fw-medium">
                ✓ Comprehensive {(() => {
                    switch(formType) {
                        case "Create": {
                            return "Created"
                        };
                        default: {
                            return `${formType}ed`
                        }
                    }
                })()} Successfully 
            </div>);
            playSound("SUCCESS");
            setTimeout(() => {
                 window.location.href = "/comprehensives"
            }, offcanvasTimer)

        } catch(e) {
            setOffcanvasMessage(<div className="p-3 border border-2 border-danger text-danger fw-medium ">
                ✗ Failed To {formType}
            </div>)
            playSound("ERROR")
            console.error(e);
        } finally {
            setShowOffcanvas(true);
            setTimeout(() => {
                navigate("/comprehensives");
                setShowOffcanvas(false);
            }, offcanvasTimer)
        }   
    }

    return (
        <form onSubmit={handleSubmit} className="nexus-form-secondary">
            <Offcanvas show={showOffcanvas} placement="end" style={{height: "fit-content"}}>
                <Offcanvas.Body className="p-0">
                    {offcanvasMsg}
                </Offcanvas.Body>
            </Offcanvas>
            <p className="nexus-form-secondary-title">{formType} Comprehensive</p>
            {loader ?
                <div className="w-100 h-100 p-5 m-auto d-flex align-items-center justify-content-center"><SpinnerLoader /></div>
                :
                <div className="d-flex flex-column gap-2 pt-2" style={{ maxHeight: "100%" }}>
                    <div className="h-100 w-100 container comprehensives-form ">
                        {
                            user.user_type === 'superAdmin' &&
                            <>
                                <div className="row mt-2">
                                    <div className="col-12 container">
                                        <div className="row">
                                            <div className={`col-4 ${formType === 'Edit' ? 'text-muted' : 'nexus-label-500'}`}>Select Firm<sup className="text-danger">*</sup></div>
                                            <select className="col-6 nexus-input-search"
                                                name="client_id"
                                                value={comprehensiveForm.client_id}
                                                onChange={handleFormChanges}
                                                disabled={formType === 'View' || formType === 'Edit'}>
                                                <option value="" disabled>-- Select a Firm --</option>
                                                {firmsList.map((firm, index) => (
                                                    <option key={index} value={firm._id}>{firm.firm_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <br />
                            </>
                        }

                        <div className="row">
                            <div className="col-12 container">
                                <div className="row">
                                    <div className="col-4 nexus-label-500">Select Course<sup className="text-danger">*</sup></div>
                                    <select className="col-6 nexus-input-search"
                                        disabled={formType === 'View'}
                                        name="course_id"
                                        value={comprehensiveForm.course_id}
                                        onChange={handleFormChanges}
                                    >
                                        <option value="" disabled>-- Select a Course --</option>
                                        {coursesList.map((course, index) => (
                                            <option key={index} value={course._id}>{course.course_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-12 container">
                                <div className="row">
                                    <div className="col-4 nexus-label-500 ">Select Subject<sup className="text-danger">*</sup></div>
                                    <select className="col-6 nexus-input-search"
                                        disabled={formType === 'View'}
                                        name="subject_id"
                                        value={comprehensiveForm.subject_id}
                                        onChange={handleFormChanges}
                                    >
                                        <option value="" disabled>-- Select a Subject --</option>
                                        {subjectsList.map((subject, index) => (
                                            <option key={index} value={subject._id}>{subject.subject_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-12 container">
                                <div className="row">
                                    <div className="col-4 nexus-label-500">Select Topic<sup className="text-danger">*</sup></div>
                                    <select className="col-6 nexus-input-search"
                                        disabled={formType === 'View'}
                                        name="topic_id"
                                        value={comprehensiveForm.topic_id}
                                        onChange={handleFormChanges}
                                    >
                                        <option value="" disabled>-- Select a Topic ----</option>
                                        {topicsList.map((topic, index) => (
                                            <option key={index} value={topic._id}>{topic.topic_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="col-12 nexus-label-500">Title<sup className="text-danger">*</sup></div>
                        <input type="text" className="nexus-input-search col-12"
                            placeholder="Enter Title" name="title"
                            disabled={formType === 'View'}
                            value={comprehensiveForm.title}
                            onChange={handleFormChanges}
                        />

                        <br /><br />

                        <div className="col-12 nexus-label-500">Content<sup className="text-danger">*</sup></div>
                        <textarea placeholder="Enter Title" className="nexus-input-search col-12"
                            name="content" rows={10}
                            disabled={formType === 'View'}
                            value={comprehensiveForm.content}
                            onChange={handleFormChanges}
                        />

                        <br />


                    </div>


                </div>
            }
            
            
            <div className={`w-100 d-flex gap-2 align-items-center justify-content-center mt-3`}>
                <div className="nexus-button-danger" onClick={() => {navigate("/comprehensives")}} >Back</div>
                {formType !== 'View' && <button type="submit" className="nexus-button-primary-dark">{formType}</button>}
            </div>
            
        </form>
    )


}