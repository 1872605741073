import {Modal} from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './questions.css';
export default function ComprehensiveQAllotmentModal({question, setShowForm}) {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const toggleAddQuestionForm = () => {
        setShowModal(false);
        navigate(`/comprehensives/question/form/add/${question._id}`)
    }

    const toggleEditQuestionForm = (comprehensiveQid) => {
        setShowModal(false);
        navigate(`/comprehensives/question/form/edit/${question._id}/${comprehensiveQid}`)
    }

    return (
        <>
            <Modal show={showModal} className='w-100 ComprehensiveQAllotmentModal'>
                <Modal.Header className='text-dark fw-medium'>
                    Alloted Comprehensive Questions
                </Modal.Header>
                <Modal.Body className='overflow-y-auto'>
                    <button className='nexus-button-primary align-items-center d-flex gap-2 float-end mb-3' onClick={toggleAddQuestionForm}>
                        <i class="bi bi-patch-question fs-4"></i> Add New Question
                    </button>
                    <table className='w-100 nexus-table-primary-stripped'>
                        <thead>
                            <td className='w-50'> Question </td>
                            <td className='w-25'> Answers </td>
                            <td className='w-25'> Actions </td>
                        </thead>
                        <tbody>
                        {question.comprehensive_questions.map(comprehensiveQuestion => (
                        <tr>
                            <td className='w-50' dangerouslySetInnerHTML={{__html: comprehensiveQuestion.question}}></td>
                            <td className='w-25'>
                                <div class="card-text questions-info-container  border-none">
                                    <div class="questions-info-result">
                                    <ul>
                                        {comprehensiveQuestion.answers.map((answer, answerIndex) => (
                                        <li key={answerIndex}><span style={{ backgroundColor: answer.is_correct ? '#b9e5b9' : 'transparent' }}>{answer.answer}</span></li>
                                        ))}
                                    </ul>
                                    </div>
                                </div>
                            </td>
                            <td className='w-50'>
                                <div className='d-flex w-100 flex-wrap gap-2 justify-content-center align-items-center'>
                                    <button className='nexus-button-primary' onClick={() => {toggleEditQuestionForm(comprehensiveQuestion._id)}}> 
                                        <i class="bi bi-pencil-square"></i>
                                    </button>
                                    <button className='nexus-button-danger'> 
                                        <i class="bi bi-trash-fill"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className='nexus-button-danger' onClick={() => {
                        setShowModal(false);
                    }}>Close</button>
                </Modal.Footer>
            </Modal>
           
            <div class="card-text questions-info-container">
                <div class="questions-info-header align-self-stretch">Questions Allotment:</div>
                <div className='d-flex align-items-center justify-content-center py-2 px-3'>
                <button className='nexus-button-primary' onClick={() => {
                    setShowModal(true);
                }}>View | Edit</button>
            </div>
            </div>
            
        </>
       
    )
}