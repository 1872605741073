import { useState, useEffect, useContext } from "react";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import SpinnerLoader from "../../helpers/spinner-loader";
import noDataIMG from "../../assets/no-data.jpg";
import { Modal, Offcanvas } from "react-bootstrap";
import "./comprehensives.scss";
import Context from '../../user_details';
import { playSound } from "../../helpers/otherSounds";

export default function Comprehensives() {
    const navigate = useNavigate();
    const [comprehensivesList, setComprehensivesList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedComprehensive, setSelectedComprehensive] = useState({});
    const [formType, setFormType] = useState('');
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [offcanvasMessage, setOffcanvasMessage] = useState("");
    const {user} = useContext(Context);
    const [showDeleteWarning, ShowWarning] = useState(false);

    useEffect(() => {
           fetchComprehensivesList();
    },[])

    const fetchComprehensivesList = async() => {
        try {
            setLoader(true);
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/qb/comprehensives/list?client_id=${user.firm_id}&type=${user.user_type}`);
            setComprehensivesList(data.result);
        } catch(e) {
            setComprehensivesList([]);
            console.error(e)
        } finally {
            setLoader(false);
        }
   }

   const triggerForm = (type, selectedComprehensive) => {
        switch(type) {
            case "Create": {
                navigate('create');
                break;
            } 
            default: {
                navigate(`${type.toLowerCase()}/${selectedComprehensive._id}`)
            }
        }
   }

   const handleDelete = async() => {
    try {
        const result = await axios.delete(`${process.env.REACT_APP_API_URL}/qb/comprehensives/delete/${selectedComprehensive._id}`);
        if(!result.data.success) throw new Error();
        setOffcanvasMessage(<div className="border border-2 border-success p-2 text-success p-3">
            ✓ Comprehensive Deleted Successfully 
         </div>);
        playSound("SUCCESS");
        fetchComprehensivesList();
    } catch(e) {
        console.error(e);
        playSound("ERROR");
        setOffcanvasMessage(<div className="border border-2 border-danger p-2 text-danger p-3">
           ✗ Failed to delete
        </div>)
    } finally {
        setShowOffcanvas(true);
        ShowWarning(false);
        setTimeout(() => {
            setShowOffcanvas(false);
        } , 2000);
        
    }
   }

    return (
        <div className="h-100 w-100">
            <Offcanvas show={showOffcanvas} placement="end" style={{height: "fit-content"}}>
                <Offcanvas.Body className="p-0">
                    {offcanvasMessage}
                </Offcanvas.Body>
            </Offcanvas>
            <Modal show={showDeleteWarning}>
                <Modal.Header>
                    <div className="fw-medium fs-5 nexus-color-red align-items-center d-flex gap-2 w-100">
                        <i class="bi bi-exclamation-triangle"></i> Warning
                    </div>
                </Modal.Header>
                <Modal.Body className="fw-medium text-dark py-4">
                   <i>
                        Deleting this Comprehensive will result in losing this comprehensive data permanently. Do you wish to proceed?
                   </i>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 align-items-center justify-content-end gap-2">
                        <button className="nexus-button-primary" onClick={() => {ShowWarning(false)}}>
                            Cancel
                        </button>
                        <button className="nexus-button-danger" onClick={handleDelete}>
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {loader ? 
                <div className=" d-flex align-items-center justify-content-center h-100 w-100"><SpinnerLoader/></div>
                    :
                <div className="w-100 h-100">
                   <div className="w-100 d-flex align-items-center px-3 gap-5 mb-4">
                        <h2 className="nexus-header-primary nexus-fs-heading fw-bold">Comprehensives</h2>
                        <button className="nexus-button-primary">
                            <div className="d-flex align-items-center gap-2">
                                <i class="bi bi-patch-plus"></i>
                                <p className="m-0" 
                                    onClick={() => {
                                        triggerForm('Create')
                                    }}
                                > Add Comprehensive</p>
                            </div>
                        </button>
                        <input type="search" className="nexus-input-search ms-auto" placeholder="Search a Title" />
                   </div>
                   <table className="nexus-table-primary-stripped w-100">
                        <thead className="fw-medium">
                            <td className="py-2" style={{width: "5%"}}>S.No</td>
                            <td className="py-2" style={{width: `${user.user_type === 'superAdmin'? "30%": "50%"}`}}>Title</td>
                            {user.user_type == 'superAdmin' && <td className="py-2" style={{width: "20%"}} >Firm</td>}
                            <td className="py-2" style={{width: "45%"}}>Actions</td>
                        </thead>
                        <tbody>
                            {!comprehensivesList.length ?
                                <tr>
                                    <td className="" colSpan={user.user_type === 'superAdmin'? 4:3}>
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <img src={noDataIMG}/>
                                            <p className="nexus-color-primary-note fw-medium">
                                                No Comprehensives Available
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                 :
                                comprehensivesList.map((comprehensive, index) => (
                                    <tr key={index} className="fw-medium">
                                        <td>{index+1}</td>
                                        <td>{comprehensive.title}</td>
                                        {user.user_type === 'superAdmin' && <td>{comprehensive.firm}</td>}
                                        <td>
                                            <div className="d-flex flex-wrap align-items-center justify-content-center gap-2 mx-auto ">
                                                <button className="nexus-button-primary fw-medium" title="edit comprehensive"
                                                    onClick={() => {triggerForm('View', comprehensive)}}
                                                >
                                                    <i class="bi bi-eye"></i> &nbsp;View
                                                </button>
                                                <button className="nexus-button-primary fw-medium" title="edit comprehensive"
                                                     onClick={() => {triggerForm('Edit', comprehensive)}}
                                                >
                                                    <i class="bi bi-pencil-square"></i> &nbsp;Edit
                                                </button>
                                                <button className="nexus-button-danger fw-medium " 
                                                    value={comprehensive._id}
                                                    title="delete comprehensive"
                                                    onClick={(e) => {
                                                        setSelectedComprehensive({_id: e.target.value});
                                                        ShowWarning(true);
                                                   }}
                                                >
                                                    <i class="bi bi-trash"></i> &nbsp; Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                   </table>
                </div>
            }
        </div>
    )
    
}