import {useState, useEffect} from 'react';
import SpinnerLoader from '../../helpers/spinner-loader';

const MarksDefinitionModal = ({Qtype, sectionValues, setSectionValues, selectedSection, setShowMarksModal}) => {
    const [qTypes, setQTypes] = useState({
        1: "Multiple Choice",
        2: "True / False",
        3: "CheckBoxes",
        4: "Comprehensives"
    });
    const [allotmentForm, setAllotmentForm] = useState(sectionValues[selectedSection - 1].marks_allotment);
    const [formLoader, setFormLoader] = useState(false);

    console.log("apai", sectionValues, selectedSection )

    useEffect(() => {
        console.log("this is allotment form", allotmentForm)
    } , [allotmentForm])

    const handleMarksAllotmentChange = (e) => {
        setFormLoader(true);
        let {name, value: marks_value} = e.target;
        let [Qtype, complexity, isNegativeMarks] = name.split('-');
        console.log("allotment values", Qtype, complexity, isNegativeMarks)
        Qtype = Number(Qtype);
        switch(Qtype) {
            case 1:
            case 2:
            case 3: {
                if(isNegativeMarks) {
                    setAllotmentForm(prev => ({...prev, [Qtype]: {...prev[Qtype], [complexity]: {...prev[Qtype][complexity], negative_marks: marks_value}}}))
                } else {
                    setAllotmentForm(prev => ({...prev, [Qtype]: {...prev[Qtype], [complexity]: {...prev[Qtype][complexity], marks: marks_value}}}))
                }
                break;
            }
            case 4: {
                if(isNegativeMarks) {
                    setAllotmentForm(prev => ({...prev, [Qtype]: {...prev[Qtype], negative_marks: marks_value}}))
                } else {
                    setAllotmentForm(prev => ({...prev, [Qtype]: {...prev[Qtype][complexity], marks: marks_value}}))
                }
            }
        }
        setFormLoader(false);
    }

    const toggleMarksModal = () => {
        setShowMarksModal(prev => !prev);
    }

    const saveMarksAllotment = () => {
        const section_values = sectionValues;
        section_values[selectedSection - 1].marks_allotment = allotmentForm;
        setSectionValues([...section_values])
        toggleMarksModal();
    }

    return (
        <div className='w-100 nexus-form-secondary'>
            <p className='nexus-form-title nexus-background-secondary fs-6' >{qTypes[Number(Qtype)]}</p>
            <div className='rounded'>
                {formLoader?
                    <div className='d-flex align-items-center justify-content-center' style={{height: '75vh'}}>
                        <SpinnerLoader/>
                    </div>
                    :
                    <table className='nexus-table-primary-stripped mt-3 w-100 ' >
                        <thead className='rounded-top'>
                            <td className='w-100'></td>
                            <td className='fw-medium fs-6'>Marks per Question</td>
                            <td className='fw-medium fs-6'>Negative Marks per Question <br />(<i><small>Deduction for Wrong Answer</small></i>)</td>
                        </thead>
                        <tbody>
                            {(() => {
                                switch (Number(Qtype)) {
                                    case 1:
                                    case 2:
                                    case 3: {
                                        return (
                                            <>
                                                <tr>
                                                    <td>Easy Question</td>
                                                    <td><input type="number" name={`${Qtype}-easy`} min={1} defaultValue={allotmentForm[Qtype].easy.marks || 1} onChange={handleMarksAllotmentChange} /></td>
                                                    <td><input type="number" name={`${Qtype}-easy-negative`} defaultValue={allotmentForm[Qtype].easy.negative_marks || 0} onChange={handleMarksAllotmentChange} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Medium Question</td>
                                                    <td><input type="number" name={`${Qtype}-medium`} min={1} defaultValue={allotmentForm[Qtype].medium.marks || 2} onChange={handleMarksAllotmentChange} /></td>
                                                    <td><input type="number" name={`${Qtype}-medium-negative`} defaultValue={allotmentForm[Qtype].medium.negative_marks || 0} onChange={handleMarksAllotmentChange} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Hard Question</td>
                                                    <td><input type="number" name={`${Qtype}-hard`} min={1} defaultValue={allotmentForm[Qtype].hard.marks || 4} onChange={handleMarksAllotmentChange} /></td>
                                                    <td><input type="number" name={`${Qtype}-hard-negative`} defaultValue={allotmentForm[Qtype].hard.negative_marks || 0} onChange={handleMarksAllotmentChange} /></td>
                                                </tr>
                                            </>
                                        )
                                    };
                                    case 4: {
                                        return (
                                            <tr>
                                                <td>Comprehesive</td>
                                                <td><input type="number" name={`${Qtype}--`} min={1} defaultValue={allotmentForm[Qtype].marks || 5} onChange={handleMarksAllotmentChange} /></td>
                                                <td><input type="number" name={`${Qtype}--negative`} defaultValue={allotmentForm[Qtype].negative_marks || 0} onChange={handleMarksAllotmentChange} /></td>
                                            </tr>
                                        )
                                    }
                                }
                            })()}
                        </tbody>
                    </table>
                }
            </div>
            <div className=' w-100 d-inline-flex p-4 pb-2 justify-content-center gap-2'>
              <button className='nexus-button-danger' onClick={toggleMarksModal}>Close</button>
              <button className='nexus-button-primary' onClick={saveMarksAllotment}>Save</button>
            </div>
        </div>

    )
}

export default MarksDefinitionModal;